import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "../context/auth"
import { convertToBgImage } from "gbimage-bridge"
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from "gatsby-background-image"
import "../css/background-image.css"
import Resizer from "react-image-file-resizer"
import VolverLogin from "../components/VolverLogin"
import axios from "axios"
import {
  Modal,
  Tabs,
  Tab,
  Button,
  CssBaseline,
  TextField,
  Typography,
  makeStyles,
  Switch,
  Select,
  InputLabel,
  MenuItem,
  Grid,
  Hidden,
  Card,
  CardContent,
  CardMedia,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import ScheduleIcon from "@material-ui/icons/Schedule"
import { injectIntl } from "gatsby-plugin-intl"
import firebase from "gatsby-plugin-firebase"
import { graphql } from "gatsby"
import ModalRondaElegirHorarioVisitante from "../components/Ronda/ModalRondaElegirHorarioVisitante"
import ModalRondaModificarEstadoReunion from "../components/Ronda/ModalRondaModificarEstadoReunion"
import usePaises from "../hooks/usePaises"
import logodefault from "../components/Ronda/logodefault"
import ModalRegistroRonda from "../components/Ronda/ModalRegistroRonda"

const useStyles = makeStyles(theme => ({
  logo: {
    width: 100,
  },
  bandera: {
    width: 20,
  },
  paper: {
    position: "absolute",
    width: "80%",
    left: "10%",
    top: "5%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const RondaDeNegocios = ({ intl, data }) => {
  const { user } = useContext(AuthContext)
  const classes = useStyles()
  const currentLocale = intl.locale
  const paises = usePaises()
  paises.sort(function (a, b) {
    let left = a.nombre_pais
    let right = b.nombre_pais
    return left === right ? 0 : left > right ? 1 : -1
  })

  const {
    nombre,
    imagen_fondo,
    modal_elegir_horario,
    bloquear_horarios_habilitado,
    mostrar_solo_empresas_expositoras,
    modal_zoom,
    modal_ronda_registro,
    modal_modificar_estado,
    modal_reunirme_ronda,
    boton_tab_participantes,
    boton_tab_miinscripcion,
    boton_tab_agenda,
    boton_tab_reuniones,
    boton_tab_reuniones_todas,
    boton_tab_reuniones_aceptadas,
    boton_tab_reuniones_pendientes,
    boton_tab_reuniones_canceladas,
    ronda_paga,
    ronda_paga_titulo,
    ronda_paga_texto,
    fechas,
  } = data.strapiRonda

  /*   function convertTZ(date, tzString) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: tzString }
      )
    )
  } */

  let fechasUTCinscripcion = {}
  fechas.map(function (fecha) {
    fecha.horas.map(function (hora) {
      //  console.log("hora: ", hora.hora)
      // let date = new Date.UTC(fecha.dia.substring(0, 4), parseInt(fecha.dia.substring(5, 7),10) - 1, fecha.dia.substring(8, 10), hora.hora.substring(0, 2), hora.hora.substring(3, 5))
      /*       let date = new Date(
        fecha.dia.substring(0, 4),
        parseInt(fecha.dia.substring(5, 7), 10) - 1,
        fecha.dia.substring(8, 10),
        hora.hora.substring(0, 2),
        hora.hora.substring(3, 5)
      ) */
      // new Date(year, monthIndex, day, hours, minutes)
      /*       console.log("date: ", date, "FECHA: ", fecha.dia, "hora: ", hora.hora)
      console.log("date to UTC string: ", date.toUTCString())
         let gmt = date.toUTCString()
   let gmtdate = new Date(date.toUTCString())
   console.log("gmtdate: ", gmtdate) 
   console.log("gmt: ", gmt)
   let dateengmt = new Date(gmt).toISOString() 
   console.log("toISOString: ", dateengmt)
  */
      let dateutc = Date.UTC(
        fecha.dia.substring(0, 4),
        parseInt(fecha.dia.substring(5, 7), 10) - 1,
        fecha.dia.substring(8, 10),
        hora.hora.substring(0, 2),
        hora.hora.substring(3, 5)
      )
      //  console.log("dateutc: ", dateutc)

      let dateutcadate = new Date(dateutc)
      // console.log("dateutc a date local: ", dateutcadate)
      // console.log("año  de dateutc: ", dateutcadate.getFullYear())
      // console.log("mes de dateutc: ", dateutcadate.getMonth() + 1)
      // console.log("dia de dateutc: ", dateutcadate.getDay())
      // console.log("date de dateutc: ", dateutcadate.getDate()) // es el dia
      // console.log("hora de dateutc: ", dateutcadate.getHours())
      //  console.log("hora de .utc: ", dateutcadate.getUTCHours())
      // console.log("minutis de : ", dateutcadate.getMinutes())
      // console.log("Fecha pintar : ", dateutcadate.toLocaleDateString())
      // console.log("Hora pintar : ", dateutcadate.toLocaleTimeString())

      for (let i = 0; i < fecha.horas.length - 1; i++) {
        fechasUTCinscripcion[dateutc] = "libre"
      }

      /*      firebase
      .firestore()
      .collection("usuarios")
      .doc("na9J0Rmj6HhfmOCRYt6VR747tA52")
      .update({
     //   dateengmt: dateengmt,
      //  gmtdate: gmtdate,
     //   gmt: gmt,
     //   date: date,
       // gmtcero: convertTZ(date, "Etc/UTC")
        dateutc: dateutc,
        horas: [{dia: "18/2", hora: "9.30", estado: "aceptada"}, {dia: "19/2", hora: "9.00", estado: "libre"}]
      })    */

      //console.log("date int: ", parseInt(fecha.dia.substring(5, 7),10) - 1)

      // console.log("convertida: ", convertTZ(date, Intl.DateTimeFormat().resolvedOptions().timeZone))
    })
  })
  /*   console.log("concatenado : ", concatenadoHoras.join(""))
  let horacaca = {}
  horacaca.caca = concatenadoHoras.join("")
  console.log("horacaca : ", horacaca) */

  //  console.log("objeto epoch fechas desde strapi : ", fechasUTCinscripcion)
  /* fechasUTCinscripcion.entries(function(fecha, index) {
  console.log("fecha map: ", fecha) 
  console.log("index fecha map : ", index) 

}) */
  /* const entries = Object.entries(fechasUTCinscripcion);
//console.log("fechas desde strapi entries : ",entries)

entries.map((fecha, index) =>{
// console.log("fecha : ",new Date(parseInt(fecha[0])), "estado : ",fecha[1])
// console.log("index : ",index)

}) */
  /*   for (let key in fechasUTCinscripcion) {
    console.log("estado", fechasUTCinscripcion[key], "/ key epoch ", key)
    // return dataHoras[key]
  } */

  const image = getImage(imagen_fondo.localFile)
  const bgImage = convertToBgImage(image)
  const [botonElegido, setBotonElegido] = useState("")
  const [docRonda, setDocRonda] = useState({})
  // const [actualizarDocRonda, setActualizarDocRonda] = useState(1) // para que actualice el documento desde firebase al cambiar en mi inscripcion
  const [reunionesOferente, setReunionesOferente] = useState([])
  const [reunionesDemandante, setReunionesDemandante] = useState([])
  const reunionesTodas = reunionesOferente.concat(reunionesDemandante)

  const [rowsParticipantes, setRowsParticipantes] = useState([])
  //const [listadoFiltrado, setListadoFiltrado] = useState([{}])
  // const [filtrado, setFiltrado] = useState(false)
  /*  rowsParticipantes.forEach(xx => {
   console.log("participantes", xx)
 }) */
  /*  const fileChangedHandler = event => {
  var fileInput = false
  if (event.target.files[0]) {
    fileInput = true
  }
  if (fileInput) {
    try {
      Resizer.imageFileResizer(
        event.target.files[0], //file
        200, //maxWidth
        200, //maxHeight
        "PNG", // compressFormat Can be either JPEG, PNG or WEBP.
        80, // quality
        0, //rotation
        uri => {
          //responseUriFunc Callback function of URI. Returns URI of resized image's base64 format. ex: uri => {console.log(uri)});
          setImagenElegida(uri)
        },
        "base64", //outputType Can be either base64, blob or file.(Default type is base64)
        100, //minWidth
        100 //minHeight
      )
    } catch (err) {
      console.log(err)
    }
  }
} */

  useEffect(() => {
    if (mostrar_solo_empresas_expositoras) {
      var unsuscribe = firebase
        .firestore()
        .collection("rondadenegocios")
        .onSnapshot(
          snapshot => {
            var filas = []
            snapshot.forEach(doc => {
              if (ronda_paga) {
                if (
                  //doc.data().email !== localStorage.getItem("email") &&
                  doc.data().expositor &&
                  doc.data().habilitado
                ) {
                  filas.push({
                    id: doc.id,
                    empresa: doc.data().empresa,
                    uid: doc.data().uid,
                    logo: doc.data().logo,
                    pais: doc.data().pais,
                    codpais: doc.data().codpais,
                    ramo: doc.data().ramo,
                    ofertas: doc.data().ofertas,
                    demandas: doc.data().demandas,
                    ciudad: doc.data().ciudad,
                    email: doc.data().email,
                    telefono: doc.data().telefono,
                    nombrerep: doc.data().nombrerep,
                    paisesopera: doc.data().paisesopera,
                    // segmentoempresa: doc.data().segmentoempresa,
                    ligareunion: doc.data().ligareunion,
                    // miinscripcion: doc.data().miinscripcion,
                  })
                }
              } else {
                if (
                  //   doc.data().email !== localStorage.getItem("email") &&
                  doc.data().expositor
                ) {
                  filas.push({
                    id: doc.id,
                    empresa: doc.data().empresa,
                    uid: doc.data().uid,
                    logo: doc.data().logo,
                    pais: doc.data().pais,
                    codpais: doc.data().codpais, //ACA CAMBIAR A DOC DATA TODO CAMBIARRRRRR
                    //  ramo: doc.data().ramo,
                    ofertas: doc.data().ofertas,
                    demandas: doc.data().demandas,
                    nombrerep: doc.data().nombrerep,
                    ciudad: doc.data().ciudad,
                    email: doc.data().email,
                    telefono: doc.data().telefono,
                    paisesopera: doc.data().paisesopera,
                    //  segmentoempresa: doc.data().segmentoempresa,
                    ligareunion: doc.data().ligareunion,
                    // miinscripcion: doc.data().miinscripcion,
                  })
                }
              }
            })
            setRowsParticipantes(filas)
            //  setListadoFiltrado(filas)

            //   console.log("ESTA ESCUCHANDO")
          },
          error => {
            console.log("Error getting documents: ", error)
          }
        )
      return function cleanup() {
        unsuscribe()
      }
    } else {
      var unsuscribe = firebase
        .firestore()
        .collection("rondadenegocios")
        .onSnapshot(
          snapshot => {
            var filas = []
            snapshot.forEach(doc => {
              // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id, " => ", doc.data())
              if (ronda_paga) {
                if (
                  //   doc.data().email !== localStorage.getItem("email") &&
                  doc.data().habilitado
                ) {
                  filas.push({
                    id: doc.id,
                    empresa: doc.data().empresa,
                    uid: doc.data().uid,
                    logo: doc.data().logo,
                    pais: doc.data().pais,
                    codpais: doc.data().codpais, //ACA CAMBIAR A DOC DATA TODO CAMBIARRRRRR
                    ramo: doc.data().ramo,
                    ofertas: doc.data().ofertas,
                    demandas: doc.data().demandas,
                    ciudad: doc.data().ciudad,
                    email: doc.data().email,
                    telefono: doc.data().telefono,
                    //   tiposolucion: doc.data().tiposolucion,
                    nombrerep: doc.data().nombrerep,
                    paisesopera: doc.data().paisesopera,
                    ligareunion: doc.data().ligareunion,

                    // miinscripcion: doc.data().miinscripcion,
                  })
                }
              } else {
                if (
                  true
                  //   doc.data().email !== localStorage.getItem("email")
                ) {
                  filas.push({
                    id: doc.id,
                    empresa: doc.data().empresa,
                    uid: doc.data().uid,
                    logo: doc.data().logo,
                    pais: doc.data().pais,
                    codpais: doc.data().codpais,
                    ramo: doc.data().ramo,
                    ofertas: doc.data().ofertas,
                    demandas: doc.data().demandas,
                    ciudad: doc.data().ciudad,
                    email: doc.data().email,
                    telefono: doc.data().telefono,
                    // tiposolucion: doc.data().tiposolucion,
                    nombrerep: doc.data().nombrerep,
                    paisesopera: doc.data().paisesopera,
                    ligareunion: doc.data().ligareunion,

                    // miinscripcion: doc.data().miinscripcion,
                  })
                }
              }
            })
            setRowsParticipantes(filas)
            // setListadoFiltrado(filas)

            //   console.log("ESTA ESCUCHANDO")
          },
          error => {
            console.log("Error getting documents: ", error)
          }
        )
      return function cleanup() {
        unsuscribe()
      }
    }
  }, [])

  const handleChangeTabs = (event, newValue) => {
    setBotonElegido(newValue)
  }
  useEffect(() => {
    if (user != null) {
      /****************
       * CARGA DOCUMENTO DE REGISTRO MIO INICIO
       ****************/
      var unsuscribeDocRonda = firebase
        .firestore()
        .collection("rondadenegocios")
        .doc(user.uid)
        .onSnapshot(doc => {
          if (doc.exists) {
            setDocRonda(doc.data())
          } else {
            setBotonElegido("Registro")
          }
        })
      return function cleanup() {
        unsuscribeDocRonda()
      }
      /**************** CARGA DOCUMENTO DE REGISTRO MIO FIN  ****************/
    }
    //  }, [user, actualizarDocRonda])
  }, [user])

  useEffect(() => {
    if (user != null) {
      /****************
       * CARGA DOCUMENTO DE TODAS LAS REUNIONES MIAS INICIO
       ****************/
      var unsuscribeOferente = firebase
        .firestore()
        .collection("reunionesronda")
        .where("oferente_uid", "==", user.uid)
        //  .where("oferente_uid", "==", user.uid)
        .onSnapshot(querySnapshot => {
          let reuniones = []
          querySnapshot.forEach(doc => {
            // reuniones.push(doc.data())
            reuniones.push({
              doc_id: doc.id,
              demandante_email: doc.data().demandante_email,
              demandante_ofertas: doc.data().demandante_ofertas,
              demandante_telefono: doc.data().demandante_telefono,
              demandante_demandas: doc.data().demandante_demandas,
              demandante_empresa: doc.data().demandante_empresa,
              demandante_pais: doc.data().demandante_pais,
              demandante_codpais: doc.data().demandante_codpais,
              demandante_ciudad: doc.data().demandante_ciudad,
              demandante_ramo: doc.data().demandante_ramo,
              demandante_logo: doc.data().demandante_logo,
              demandante_uid: doc.data().demandante_uid,
              demandante_estado: doc.data().demandante_estado,
              demandante_nombre: doc.data().demandante_nombre,
              demandante_apellido: doc.data().demandante_apellido,
              demandante_nombrerep: doc.data().demandante_nombrerep,
              demandante_paisesopera: doc.data().demandante_paisesopera,
              oferente_email: doc.data().oferente_email,
              oferente_ofertas: doc.data().oferente_ofertas,
              oferente_telefono: doc.data().oferente_telefono,
              oferente_demandas: doc.data().oferente_demandas,
              oferente_empresa: doc.data().oferente_empresa,
              oferente_pais: doc.data().oferente_pais,
              oferente_codpais: doc.data().oferente_codpais,
              oferente_ciudad: doc.data().oferente_ciudad,
              oferente_ramo: doc.data().oferente_ramo,
              oferente_logo: doc.data().oferente_logo,
              oferente_uid: doc.data().oferente_uid,
              oferente_estado: doc.data().oferente_estado,
              oferente_nombrerep: doc.data().nombrerep,
              oferente_paisesopera: doc.data().paisesopera,
              // oferente_tiposolucion: doc.data().tiposolucion,
              hora_reunion: doc.data().hora_reunion,
              fecha_solicitud: doc.data().fecha_solicitud, // esta tiene que ser en epoch
              ligareunion: doc.data().ligareunion ? doc.data().ligareunion : "", // si esta puesto liga unica no se muestre
            })
          })
          setReunionesOferente(reuniones)
        })
      return function cleanup() {
        unsuscribeOferente()
      }
      /**************** CARGA DOCUMENTO DE TODAS LAS REUNIONES MIAS FIN  ****************/
    }
  }, [user])

  useEffect(() => {
    if (user != null) {
      /**************** CARGA DOCUMENTO DE TODAS LAS REUNIONES demandante INICIO ****************/
      var unsuscribeDemandante = firebase
        .firestore()
        .collection("reunionesronda")
        .where("demandante_uid", "==", user.uid)
        //  .where("oferente_uid", "==", user.uid)
        .onSnapshot(querySnapshot => {
          let reuniones = []
          querySnapshot.forEach(doc => {
            //   reuniones.push(doc.data())
            reuniones.push({
              doc_id: doc.id,
              demandante_email: doc.data().demandante_email,
              demandante_ofertas: doc.data().demandante_ofertas,
              demandante_telefono: doc.data().demandante_telefono,
              demandante_demandas: doc.data().demandante_demandas,
              demandante_empresa: doc.data().demandante_empresa,
              demandante_pais: doc.data().demandante_pais,
              demandante_codpais: doc.data().demandante_codpais,
              demandante_ciudad: doc.data().demandante_ciudad,
              demandante_ramo: doc.data().demandante_ramo,
              demandante_logo: doc.data().demandante_logo,
              demandante_uid: doc.data().demandante_uid,
              demandante_estado: doc.data().demandante_estado,
              demandante_nombre: doc.data().demandante_nombre,
              demandante_apellido: doc.data().demandante_apellido,
              demandante_nombrerep: doc.data().demandante_nombrerep,
              demandante_paisesopera: doc.data().demandante_paisesopera,
              oferente_email: doc.data().oferente_email,
              oferente_ofertas: doc.data().oferente_ofertas,
              oferente_telefono: doc.data().oferente_telefono,
              oferente_demandas: doc.data().oferente_demandas,
              oferente_empresa: doc.data().oferente_empresa,
              oferente_pais: doc.data().oferente_pais,
              oferente_codpais: doc.data().oferente_codpais,
              oferente_ciudad: doc.data().oferente_ciudad,
              oferente_ramo: doc.data().oferente_ramo,
              oferente_logo: doc.data().oferente_logo,
              oferente_uid: doc.data().oferente_uid,
              oferente_estado: doc.data().oferente_estado,
              oferente_nombrerep: doc.data().nombrerep,
              oferente_paisesopera: doc.data().paisesopera,
              // oferente_tiposolucion: doc.data().tiposolucion,
              hora_reunion: doc.data().hora_reunion,
              fecha_solicitud: doc.data().fecha_solicitud,
              ligareunion: doc.data().ligareunion ? doc.data().ligareunion : "",
            })
          })
          setReunionesDemandante(reuniones)
        })
      return function cleanup() {
        unsuscribeDemandante()
      }
      /****************
       * CARGA DOCUMENTO DE TODAS LAS REUNNIONES demandante FIN
       ****************/
    }
  }, [user])
  /****************
   * CONSTANTES INICIO
   */

  const ramos_es = [
    "Industria",
    "Automotor",
    "Aviación y aeroespacial",
    "Materiales de construcción y construcción",
    "Tecnología limpia",
    "Bienes de consumo",
    "Defender y Homeland Security",
    "Educación",
    "Servicio Financiero y Profesional",
    "Alimentos / Agricultura",
    "Tecnologías de la información",
    "Logística, distribución e infraestructura",
    "Ciencias de la vida y tecnología médica",
    "Fabricación",
    "Embarcaciones y equipos marinos",
    "Otro",
  ]
  const ramos_en = [
    "Industry",
    "Automotive",
    "Aviation and aerospace",
    "Building and construction materials",
    "Clean technology",
    "Consumer goods",
    "Defender and Homeland Security",
    "Education",
    "Financial and Professional Service",
    "Food / Agriculture",
    "Information technology",
    "Logistics, distribution, and infrastructure",
    "Life Sciences and Medical Technology",
    "Manufacturing",
    "Marine vessels and equipment",
    "Other",
  ]
  const ramos_pt = [
    "Indústria",
    "Automotivo",
    "Aviação e aeroespacial",
    "Construção e materiais de construção",
    "Tecnologia limpa",
    "Bens de consumo",
    "Defender e Segurança Interna",
    "Educação",
    "Serviço Financeiro e Profissional",
    "Alimentos / Agricultura",
    "Tecnologia da Informação",
    "Logística, distribuição e infraestrutura",
    "Ciências da Vida e Tecnologia Médica",
    "Manufatura",
    "Embarcações e equipamentos marítimos",
    "Outro",
  ]

  /** constantes final **/

  /***************** Registro inicio ****************/
  const Registro = () => {
    return (
      <ModalRegistroRonda
        fechasUTCinscripcion={fechasUTCinscripcion}
        modal_ronda_registro={modal_ronda_registro}
        user={user}
        setDocRonda={setDocRonda}
        setBotonElegido={setBotonElegido}
        bloquear_horarios_habilitado={bloquear_horarios_habilitado}
        modal_zoom={modal_zoom}
      />
    )
  }
  /*****************
   * Registro fin
   ****************/

  /*****************
   * Zoom inicio
   ****************/
  const Zoom = () => {
    // ver si fiorella quiere ver algo de fondo, el coso de zoom no se ve, por ahi un boton para que descarguen zoom?
    return (
      <div className={classes.paper}>
        <CssBaseline />
        <div>
          <Typography
            component="h6"
            variant="h6"
            style={{
              fontSize: `${modal_zoom.texto_titulo_zoom.fuente_size}vw`,
            }}
          >
            <br />
            {currentLocale === "en"
              ? modal_zoom.texto_titulo_zoom.en
              : currentLocale === "pt"
              ? modal_zoom.texto_titulo_zoom.pt
              : modal_zoom.texto_titulo_zoom.es}
          </Typography>
          <p>
            {currentLocale === "en"
              ? modal_zoom.texto_contenido_zoom.en
              : currentLocale === "pt"
              ? modal_zoom.texto_contenido_zoom.pt
              : modal_zoom.texto_contenido_zoom.es}
          </p>
        </div>
        <iframe
          //className={classes.iframevideo}
          style={{ overflow: "hidden", width: "0%", height: "0%" }}
          src={modal_zoom.link_zoom}
          title="Zoom"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          allow="autoplay"
        />
      </div>
    )
  }

  /*****************
   * Zoom fin *
   ****************/
  const LigaReunion = () => {
    const [data, setData] = useState({
      ligareunion: docRonda.ligareunion,
    })
    const handleChange = e => {
      setData({ ...data, [e.target.name]: e.target.value })
    }

    const handleSubmit = e => {
      e.preventDefault()
      setData({ ...data })
      firebase.firestore().collection("rondadenegocios").doc(user.uid).update({
        ligareunion: data.ligareunion,
      })
      //  setActualizarDocRonda(actualizarDocRonda + 1)
      alert(
        currentLocale === "en"
          ? modal_ronda_registro.alert_modificar_inscripcion.en
          : currentLocale === "pt"
          ? modal_ronda_registro.alert_modificar_inscripcion.pt
          : modal_ronda_registro.alert_modificar_inscripcion.es
      )
    }
    return (
      <div className={classes.paper}>
        <CssBaseline />
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="ligareunion"
            label={
              currentLocale === "en"
                ? modal_ronda_registro.label_liga_reunion.en
                : currentLocale === "pt"
                ? modal_ronda_registro.label_liga_reunion.pt
                : modal_ronda_registro.label_liga_reunion.es
            }
            type="text"
            id="ligareunion"
            value={data.ligareunion}
            onChange={handleChange}
          />

          <Button
            type="submit"
            fullWidth
            //    variant={boton_registro_aceptar.variante}
            style={{
              background: `${modal_ronda_registro.boton_inscripcion_aceptar.color_fondo}`,
              color: `${modal_ronda_registro.boton_inscripcion_aceptar.color_letra}`,
              marginTop: 20,
            }}
            // size={modal_ronda_registro.boton_inscripcion_aceptar.size}
          >
            {currentLocale === "en"
              ? modal_ronda_registro.boton_inscripcion_aceptar.en
              : currentLocale === "pt"
              ? modal_ronda_registro.boton_inscripcion_aceptar.pt
              : modal_ronda_registro.boton_inscripcion_aceptar.es}
          </Button>
        </form>
      </div>
    )
  }
  /***************** Mi Inscripcion inicio ****************/
  const MiInscripcion = () => {
    const [imagenElegida, setImagenElegida] = useState("")
    const [pais, setPais] = useState("")
    const handleChangePais = event => {
      setPais(event.target.value)
    }
    const fileChangedHandler = event => {
      var fileInput = false
      if (event.target.files[0]) {
        fileInput = true
      }
      if (fileInput) {
        try {
          Resizer.imageFileResizer(
            event.target.files[0], //file
            200, //maxWidth
            200, //maxHeight
            "PNG", // compressFormat Can be either JPEG, PNG or WEBP.
            80, // quality
            0, //rotation
            uri => {
              //responseUriFunc Callback function of URI. Returns URI of resized image's base64 format. ex: uri => {console.log(uri)});
              setImagenElegida(uri)
            },
            "base64", //outputType Can be either base64, blob or file.(Default type is base64)
            100, //minWidth
            100 //minHeight
          )
        } catch (err) {
          console.log(err)
        }
      }
    }
    const ramos =
      currentLocale === "en"
        ? ramos_en
        : currentLocale === "pt"
        ? ramos_pt
        : ramos_es
    const handleChangeRamo = event => {
      setRamo(event.target.value)
    }

    const [ramo, setRamo] = useState("")
    const [data, setData] = useState({
      email: "",
      ofertas: "",
      telefono: "",
      demandas: "",
      empresa: "",
      ciudad: "",
      nombrerep: "",
      paisesopera: "",
      ligareunion: "",
    })

    const [dataHoras, setDataHoras] = useState({})
    useEffect(() => {
      setPais(docRonda.pais)
      setRamo(docRonda.ramo)
      setImagenElegida(docRonda.logo)
      // setSegmentoEmpresa(docRonda.segmentoempresa)
      setData({
        email: docRonda.email,
        telefono: docRonda.telefono,
        empresa: docRonda.empresa,
        ciudad: docRonda.ciudad,
        ofertas: docRonda.ofertas,
        demandas: docRonda.demandas,
        nombrerep: docRonda.nombrerep,
        paisesopera: docRonda.paisesopera,
        ligareunion: docRonda.ligareunion,
      })
      setDataHoras(docRonda.horas)
    }, [docRonda]) // aca si hay cambio agregar una variable para que actualice como la de + 1 que tengo en otro

    const handleChange = e => {
      setData({ ...data, [e.target.name]: e.target.value })
    }

    const handleSubmit = e => {
      e.preventDefault()
      setData({ ...data })
      if (
        data.nombrerep !== "" &&
        // data.ligareunion !== "" &&
        data.ofertas !== "" &&
        // data.demandas !== "" &&
        data.paisesopera !== ""
      ) {
        firebase
          .firestore()
          .collection("rondadenegocios")
          .doc(user.uid)
          .update({
            //     email: data.email,
            ofertas: data.ofertas,
            telefono: data.telefono,
            // segmentoempresa: segmentoEmpresa,
            demandas: data.demandas,
            empresa: data.empresa,
            pais: pais,
            codpais: CodigoPais(pais),
            ciudad: data.ciudad,
            ramo: ramo,
            logo: imagenElegida,
            nombrerep: data.nombrerep,
            paisesopera: data.paisesopera,
            ligareunion: data.ligareunion,
            // miinscripcion: true,
            horas: dataHoras,
          })
          .then(function () {
            //   setActualizarDocRonda(actualizarDocRonda + 1)// ver si pongo docRonda en tiempo real
            alert(
              currentLocale === "en"
                ? modal_ronda_registro.alert_modificar_inscripcion.en
                : currentLocale === "pt"
                ? modal_ronda_registro.alert_modificar_inscripcion.pt
                : modal_ronda_registro.alert_modificar_inscripcion.es
            )
            setBotonElegido("Participantes")
          })
      } else {
        alert(
          currentLocale === "en"
            ? "You must complete all the fields"
            : currentLocale === "pt"
            ? "Debe completar todos los campos"
            : "Debe completar todos los campos"
        )
      }
    }

    const checkDataHoras = hora => {
      for (let key in dataHoras) {
        if (hora === key) {
          return dataHoras[key] === "ocupado" ? false : true
        }
      }
    }
    const checkDataHorasAceptada = hora => {
      for (let key in dataHoras) {
        if (hora === key) {
          return dataHoras[key] === "aceptada" ? false : true
        }
      }
    }
    const handleChangeHorasOcupado = event => {
      setDataHoras({
        ...dataHoras,
        [event.target.name]: event.target.checked ? "libre" : "ocupado",
      })
      // setDataHoras({ ...dataHoras, [event.target.name]: event.target.checked })
    }
    const ListaHoras = () => {
      let aaa = []
      for (let key in fechasUTCinscripcion) {
        if (checkDataHorasAceptada(key)) {
          aaa.push(
            <Grid item sm={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={checkDataHoras(key)}
                    onChange={handleChangeHorasOcupado}
                    name={key}
                    color="primary"
                  />
                }
                label={new Date(parseInt(key)).toLocaleString()}
              />
            </Grid>
          )
        } else {
          aaa.push(
            <Grid item sm={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={false}
                    onChange={() => {
                      alert(
                        "no puede modificar si ya tiene una reunion aceptada en ese horario, cancele primero la reunion y luego puede modificar"
                      )
                    }}
                    name={key}
                    color="primary"
                  />
                }
                label={new Date(parseInt(key)).toLocaleString()}
              />
            </Grid>
          )
        }
      }
      return aaa
    }
    return (
      <div className={classes.paper}>
        <CssBaseline />
        <div>
          <Hidden xsDown>
            <Typography
              component="h6"
              variant="h6"
              style={{
                fontSize: `${modal_ronda_registro.texto_titulo_mi_inscripcion.fuente_size}vw`,
              }}
            >
              <br />
              {currentLocale === "en"
                ? modal_ronda_registro.texto_titulo_mi_inscripcion.en
                : currentLocale === "pt"
                ? modal_ronda_registro.texto_titulo_mi_inscripcion.pt
                : modal_ronda_registro.texto_titulo_mi_inscripcion.es}
            </Typography>
          </Hidden>
          <Hidden smUp>
            <Typography component="p" variant="p">
              <br />
              {currentLocale === "en"
                ? modal_ronda_registro.texto_titulo_mi_inscripcion.en
                : currentLocale === "pt"
                ? modal_ronda_registro.texto_titulo_mi_inscripcion.pt
                : modal_ronda_registro.texto_titulo_mi_inscripcion.es}
            </Typography>
          </Hidden>

          <form noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="ofertas"
              label={
                currentLocale === "en"
                  ? modal_ronda_registro.label_ofertas.en
                  : currentLocale === "pt"
                  ? modal_ronda_registro.label_ofertas.pt
                  : modal_ronda_registro.label_ofertas.es
              }
              type="text"
              id="ofertas"
              value={data.ofertas}
              onChange={handleChange}
            />
            {modal_ronda_registro.demandas_mostrar ? (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="demandas"
                label={
                  currentLocale === "en"
                    ? modal_ronda_registro.label_demandas.en
                    : currentLocale === "pt"
                    ? modal_ronda_registro.label_demandas.pt
                    : modal_ronda_registro.label_demandas.es
                }
                type="text"
                id="demandas"
                value={data.demandas}
                onChange={handleChange}
              />
            ) : (
              ""
            )}
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="telefono"
              label={
                currentLocale === "en"
                  ? modal_ronda_registro.label_telefono.en
                  : currentLocale === "pt"
                  ? modal_ronda_registro.label_telefono.pt
                  : modal_ronda_registro.label_telefono.es
              }
              type="tel"
              id="telefono"
              value={data.telefono}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="empresa"
              label={
                currentLocale === "en"
                  ? modal_ronda_registro.label_empresa.en
                  : currentLocale === "pt"
                  ? modal_ronda_registro.label_empresa.pt
                  : modal_ronda_registro.label_empresa.es
              }
              type="text"
              id="empresa"
              value={data.empresa}
              onChange={handleChange}
            />

            <InputLabel id="paisLabel" style={{ padding: 5 }}>
              {currentLocale === "en"
                ? modal_ronda_registro.label_pais.en
                : currentLocale === "pt"
                ? modal_ronda_registro.label_pais.pt
                : modal_ronda_registro.label_pais.es}
            </InputLabel>
            <Select
              style={{ width: "100%", padding: 5 }}
              id="pais"
              value={pais}
              onChange={handleChangePais}
            >
              {paises.map(pais => (
                <MenuItem value={pais.nombre.es}>
                  {pais.mostrar ? (
                    <>
                      {currentLocale === "en"
                        ? pais.nombre.en
                        : currentLocale === "pt"
                        ? pais.nombre.pt
                        : pais.nombre.es}
                    </>
                  ) : (
                    ""
                  )}
                </MenuItem>
              ))}
            </Select>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="ciudad"
              label={
                currentLocale === "en"
                  ? modal_ronda_registro.label_ciudad.en
                  : currentLocale === "pt"
                  ? modal_ronda_registro.label_ciudad.pt
                  : modal_ronda_registro.label_ciudad.es
              }
              type="text"
              id="ciudad"
              value={data.ciudad}
              onChange={handleChange}
            />

            <InputLabel id="ramosLabel" style={{ padding: 5 }}>
              {currentLocale === "en"
                ? modal_ronda_registro.label_rubros.en
                : currentLocale === "pt"
                ? modal_ronda_registro.label_rubros.pt
                : modal_ronda_registro.label_rubros.es}
            </InputLabel>
            <Select
              style={{ width: "100%", padding: 5 }}
              id="categoria"
              value={ramo}
              onChange={handleChangeRamo}
            >
              {ramos.map(ramo => (
                <MenuItem value={ramo}>{ramo}</MenuItem>
              ))}
            </Select>
            <div>&nbsp;</div>
            <div style={{ textAlign: "center" }}>
              {imagenElegida === null ||
              imagenElegida === "undefined" ||
              imagenElegida === "" ? (
                <Button component="label">
                  {currentLocale === "en" ? (
                    <StaticImage
                      src="../images/subir_imagen_en.png"
                      alt="Subir Imagen"
                    />
                  ) : currentLocale === "pt" ? (
                    <StaticImage
                      src="../images/subir_imagen_pt.png"
                      alt="Subir Imagen"
                    />
                  ) : (
                    <StaticImage
                      src="../images/subir_imagen.png"
                      alt="Subir Imagen"
                    />
                  )}

                  <input type="file" hidden onChange={fileChangedHandler} />
                </Button>
              ) : (
                <Button component="label">
                  <img src={`${imagenElegida}`} alt="" />

                  <input type="file" hidden onChange={fileChangedHandler} />
                </Button>
              )}
            </div>
            {modal_ronda_registro.paisesopera_mostrar ? (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="paisesopera"
                label={
                  currentLocale === "en"
                    ? modal_ronda_registro.label_paisesopera.en
                    : currentLocale === "pt"
                    ? modal_ronda_registro.label_paisesopera.pt
                    : modal_ronda_registro.label_paisesopera.es
                }
                type="text"
                id="paisesopera"
                value={data.paisesopera}
                onChange={handleChange}
              />
            ) : (
              ""
            )}
            {modal_ronda_registro.representante_mostrar ? (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="nombrerep"
                label={
                  currentLocale === "en"
                    ? modal_ronda_registro.label_representante.en
                    : currentLocale === "pt"
                    ? modal_ronda_registro.label_representante.pt
                    : modal_ronda_registro.label_representante.es
                }
                type="text"
                id="nombrerep"
                value={data.nombrerep}
                onChange={handleChange}
              />
            ) : (
              ""
            )}

            {modal_zoom.liga_reunion_unica ? (
              ""
            ) : (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="ligareunion"
                label={
                  currentLocale === "en"
                    ? modal_ronda_registro.label_liga_reunion.en
                    : currentLocale === "pt"
                    ? modal_ronda_registro.label_liga_reunion.pt
                    : modal_ronda_registro.label_liga_reunion.es
                }
                type="text"
                id="ligareunion"
                value={data.ligareunion}
                onChange={handleChange}
              />
            )}

            {bloquear_horarios_habilitado ? (
              <>
                <InputLabel id="horas" style={{ padding: 15 }}>
                  {currentLocale === "en"
                    ? modal_ronda_registro.label_horarios.en
                    : currentLocale === "pt"
                    ? modal_ronda_registro.label_horarios.pt
                    : modal_ronda_registro.label_horarios.es}
                </InputLabel>
                <Grid container>
                  <ListaHoras />
                </Grid>
              </>
            ) : (
              ""
            )}

            <Button
              type="submit"
              fullWidth
              //    variant={boton_registro_aceptar.variante}
              style={{
                background: `${modal_ronda_registro.boton_inscripcion_aceptar.color_fondo}`,
                color: `${modal_ronda_registro.boton_inscripcion_aceptar.color_letra}`,
                marginTop: 20,
              }}
              // size={modal_ronda_registro.boton_inscripcion_aceptar.size}
            >
              {currentLocale === "en"
                ? modal_ronda_registro.boton_inscripcion_aceptar.en
                : currentLocale === "pt"
                ? modal_ronda_registro.boton_inscripcion_aceptar.pt
                : modal_ronda_registro.boton_inscripcion_aceptar.es}
            </Button>
          </form>
        </div>
      </div>
    )
  }
  /*****************
   * Mi Inscripcion fin
   ****************/

  /*****************
   * Reuniones inicio
   ****************/
  const Reuniones = () => {
    //en useEffect principal me traigo todas las reuniones que hacen match con mi email en solicitante y solicitado
    //despues lo uso en las pestañas que necesite con un nuevo filtro
    const [botonElegidoReuniones, setBotonElegidoReuniones] = useState("Todas")
    const handleChangeTabsReuniones = (event, newValue) => {
      setBotonElegidoReuniones(newValue)
    }

    const ligaReunion = ligaLink => {
      let devolver = ""

      rowsParticipantes.filter(function (el) {
        //console.log("coincide:", el.id === ligaLink)
        //console.log("participante id", el.id)
        //   console.log("id oferente:", ligaLink, "participante id:", el.id, "coincide:", el.id === ligaLink)

        if (el.id === ligaLink) {
          devolver = el.ligareunion
        }
      })
      return devolver
    }
    const empresaDevolver = uid => {
      let devolver = ""
      rowsParticipantes.filter(function (el) {
        //console.log("coincide:", el.id === ligaLink)
        //console.log("participante id", el.id)
        //   console.log("id oferente:", ligaLink, "participante id:", el.id, "coincide:", el.id === ligaLink)

        if (el.id === uid) {
          devolver = el.empresa
        }
      })
      return devolver
    }
    /** ICS inicio */
    var icsFile = null
    function createFileICS(hora, empresa) {
      var eventDate = {
          start: hora,
          end: hora,
        },
        summary = empresa,
        description = ""
      const link = document.createElement("a")
      link.download = `${empresa}.ics`
      link.href = makeIcsFile(eventDate, summary, description)
      link.click()
    }
    function convertDate(date) {
      // parseInt me parece que falta
      // var event = new Date(parseInt(date)).toLocaleString()
      var horaLocal = new Date(parseInt(date))
      const pad = function (i) {
        return i < 10 ? "0" + i : i
      }
      // var d = new Date("Sat Jul 21 2018 14:00:00 GMT+0200");
      var Y = horaLocal.getFullYear()
      var m = horaLocal.getMonth() + 1
      var D = horaLocal.getDate()
      var H = horaLocal.getHours()
      var M = horaLocal.getMinutes()
      var S = horaLocal.getSeconds()
      // var s = Y  +  pad(m) + pad(D) + 'T' + pad(H)  + pad(M)  + pad(S);
      // console.log("partido", s);

      //  console.log("mes:", (horaLocal.getMonth() + 1).toString(),"dia:", horaLocal.getDate(), "año:", horaLocal.getFullYear(),  )
      //  var event = new Date(horaLocal).toISOString()
      // var event = new Date(date).toISOString()
      // event = event.split("T")[0]
      /*    event = event.split(".")[0]
      event = event.split("-")
      event = event.join("")
      event = event.split(":")
      event = event.join("") 
      return event */
      return Y + pad(m) + pad(D) + "T" + pad(H) + pad(M) + pad(S)
    }
    function convertDateEnd(date) {
      var horaLocal = new Date(parseInt(date))
      horaLocal.setMinutes(horaLocal.getMinutes() + 30)
      const pad = function (i) {
        return i < 10 ? "0" + i : i
      }
      var Y = horaLocal.getFullYear()
      var m = horaLocal.getMonth() + 1
      var D = horaLocal.getDate()
      var H = horaLocal.getHours()
      var M = horaLocal.getMinutes()
      var S = horaLocal.getSeconds()

      return Y + pad(m) + pad(D) + "T" + pad(H) + pad(M) + pad(S)
    }
    function makeIcsFile(date, summary, description) {
      var test =
        "BEGIN:VCALENDAR\n" +
        "CALSCALE:GREGORIAN\n" +
        "METHOD:PUBLISH\n" +
        "PRODID:-//View//EN\n" +
        "VERSION:2.0\n" +
        "BEGIN:VEVENT\n" +
        "UID:test-1\n" +
        "DTSTART;VALUE=DATE-TIME:" +
        convertDate(date.start) +
        "\n" +
        "DTEND;VALUE=DATE-TIME:" +
        convertDateEnd(date.end) +
        "\n" +
        "SUMMARY:" +
        summary +
        "\n" +
        "DESCRIPTION:" +
        description +
        "\n" +
        "END:VEVENT\n" +
        "END:VCALENDAR"

      var data = new File([test], { type: "text/plain" })

      // If we are replacing a previously generated file we need to
      // manually revoke the object URL to avoid memory leaks.
      if (icsFile !== null) {
        window.URL.revokeObjectURL(icsFile)
      }

      icsFile = window.URL.createObjectURL(data)

      return icsFile
    }

    //////////////////////

    const TemplateReuniones = props => {
      const lista = props.listado
      /*    lista.sort(function (a, b) {
        if (a.hora_reunion > b.hora_reunion) {
          return 1;
        }
        if (a.hora_reunion < b.hora_reunion) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });  */
      lista.sort(function (a, b) {
        if (a.hora_reunion.substring(1, 5) > b.hora_reunion.substring(1, 5)) {
          return 1
        }
        if (a.hora_reunion.substring(1, 5) < b.hora_reunion.substring(1, 5)) {
          return -1
        }
        // a must be equal to b
        return 0
      })
      lista.sort(function (a, b) {
        if (a.hora_reunion.substring(6, 7) > b.hora_reunion.substring(6, 7)) {
          return 1
        }
        if (a.hora_reunion.substring(6, 7) < b.hora_reunion.substring(6, 7)) {
          return -1
        }
        // a must be equal to b
        return 0
      })
      return (
        <Grid
          container
          spacing={1}
          direction="column"
          alignItems="center"
          justify="center"
        >
          {lista.map(reunion => (
            <>
              <Hidden xsDown>
                <Grid item xs={8} sm={8} key={reunion.id}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={8}>
                              {reunion.demandante_uid === user.uid ? (
                                <Typography
                                  noWrap
                                  variant="h6"
                                  component="h6"
                                  style={{ fontSize: 24 }}
                                >
                                  {empresaDevolver(reunion.oferente_uid)}
                                </Typography>
                              ) : (
                                <Typography
                                  noWrap
                                  variant="h6"
                                  component="h6"
                                  style={{ fontSize: 24 }}
                                >
                                  {empresaDevolver(reunion.demandante_uid)}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={4}>
                              {reunion.demandante_estado === "aceptada" &&
                              reunion.oferente_estado === "aceptada" ? (
                                <>
                                  <ScheduleIcon />
                                  {new Date(
                                    parseInt(reunion.hora_reunion)
                                  ).toLocaleString()}
                                  {reunion.demandante_uid === user.uid ? (
                                    <Button
                                      onClick={() => {
                                        createFileICS(
                                          reunion.hora_reunion,
                                          reunion.oferente_empresa
                                        )
                                      }}
                                    >
                                      <StaticImage
                                        src="../images/ics.png"
                                        alt="ICS"
                                      />
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        createFileICS(
                                          reunion.hora_reunion,
                                          reunion.demandante_empresa
                                        )
                                      }}
                                    >
                                      <StaticImage
                                        src="../images/ics.png"
                                        alt="ICS"
                                      />
                                    </Button>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </Grid>

                            <Grid item xs={11}>
                              <Typography
                                noWrap
                                variant="h6"
                                component="h6"
                                style={{ fontStyle: "italic", fontSize: 14 }}
                              >
                                {currentLocale === "en"
                                  ? "Requested by :"
                                  : currentLocale === "pt"
                                  ? "Requerido por :"
                                  : "Requerido por :"}{" "}
                                {empresaDevolver(reunion.demandante_uid)}
                                {" - "}
                                {reunion.demandante_nombre}{" "}
                                {reunion.demandante_apellido}
                              </Typography>
                            </Grid>

                            <Hidden xsDown>
                              <Grid item xs={12}>
                                <Typography
                                  noWrap
                                  variant="body2"
                                  component="p"
                                  gutterBottom
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    {currentLocale === "en"
                                      ? "Estado oferente :"
                                      : currentLocale === "pt"
                                      ? "Estado oferente :"
                                      : "Estado oferente :"}{" "}
                                  </span>
                                  <span style={{ fontStyle: "italic" }}>
                                    {reunion.oferente_estado}
                                  </span>
                                  {"     "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {currentLocale === "en"
                                      ? "Estado demandante :"
                                      : currentLocale === "pt"
                                      ? "Estado demandante :"
                                      : "Estado demandante :"}{" "}
                                  </span>
                                  <span style={{ fontStyle: "italic" }}>
                                    {reunion.demandante_estado}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item xs={6}>
                                    <Typography
                                      noWrap
                                      variant="body2"
                                      component="p"
                                      gutterBottom
                                    ></Typography>
                                    {reunion.oferente_uid === user.uid &&
                                    reunion.oferente_estado === "pendiente" ? (
                                      ""
                                    ) : (
                                      /*                                   <ModalRondaElegirHorarioVisitante
                                    reunion={reunion}
                                    modal_elegir_horario={modal_elegir_horario}
                                    horas_disponibles={horas_disponibles}
                                  /> */
                                      <ModalRondaModificarEstadoReunion
                                        reunion={reunion}
                                        modal_modificar_estado={
                                          modal_modificar_estado
                                        }
                                      />
                                    )}
                                  </Grid>

                                  <Grid
                                    item
                                    xs={6}
                                    container
                                    justify="flex-end"
                                  >
                                    {reunion.demandante_estado === "aceptada" &&
                                    reunion.oferente_estado === "aceptada" &&
                                    !modal_zoom.liga_reunion_unica ? (
                                      <Button
                                        variant="outlined"
                                        //          variant={boton_modal_aquiestoy.variante}
                                        style={{
                                          background: "#352364",
                                          color: "#FFF",
                                          // maxHeight: 20
                                        }}
                                      >
                                        <a
                                          href={ligaReunion(
                                            reunion.oferente_uid
                                          )}
                                          style={{
                                            textDecoration: "none",
                                            color: "#FFF",
                                          }}
                                          rel="noreferrer"
                                          target="_blank"
                                        >
                                          {currentLocale === "en"
                                            ? "Join the meeting"
                                            : currentLocale === "pt"
                                            ? "Entrar na reunião"
                                            : "Ingresar a la reunión"}
                                        </a>
                                      </Button>
                                    ) : null}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Hidden>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Hidden>

              {/** reuniones template celular inicio */}
              <Hidden smUp>
                <Grid item xs={12} sm={12} key={reunion.id}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          {reunion.demandante_uid === user.uid ? (
                            <Typography
                              noWrap
                              variant="h6"
                              component="h6"
                              style={{ fontSize: 24 }}
                            >
                              {reunion.oferente_empresa}
                            </Typography>
                          ) : (
                            <Typography
                              noWrap
                              variant="h6"
                              component="h6"
                              style={{ fontSize: 24 }}
                            >
                              {reunion.demandante_empresa}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {reunion.demandante_estado === "aceptada" &&
                          reunion.oferente_estado === "aceptada" ? (
                            <>
                              <ScheduleIcon />
                              {new Date(
                                parseInt(reunion.hora_reunion)
                              ).toLocaleString()}
                            </>
                          ) : (
                            ""
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            noWrap
                            variant="h6"
                            component="h6"
                            style={{ fontStyle: "italic", fontSize: 12 }}
                          >
                            {currentLocale === "en"
                              ? "Requested by :"
                              : currentLocale === "pt"
                              ? "Requerido por :"
                              : "Requerido por :"}{" "}
                            {empresaDevolver(reunion.demandante_uid)}
                            {" - "}
                            {reunion.demandante_nombre}{" "}
                            {reunion.demandante_apellido}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            noWrap
                            variant="body2"
                            component="p"
                            gutterBottom
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {currentLocale === "en"
                                ? "Estado oferente :"
                                : currentLocale === "pt"
                                ? "Estado oferente :"
                                : "Estado oferente :"}{" "}
                            </span>
                            <span style={{ fontStyle: "italic" }}>
                              {reunion.oferente_estado}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            noWrap
                            variant="body2"
                            component="p"
                            gutterBottom
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {currentLocale === "en"
                                ? "Estado demandante :"
                                : currentLocale === "pt"
                                ? "Estado demandante :"
                                : "Estado demandante :"}{" "}
                            </span>
                            <span style={{ fontStyle: "italic" }}>
                              {reunion.demandante_estado}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} container justify="flex-end">
                          <Typography
                            noWrap
                            variant="body2"
                            component="p"
                            gutterBottom
                          ></Typography>
                          {reunion.oferente_uid === user.uid &&
                          reunion.oferente_estado === "pendiente" ? (
                            ""
                          ) : (
                            <ModalRondaModificarEstadoReunion
                              reunion={reunion}
                              modal_modificar_estado={modal_modificar_estado}
                            />
                          )}
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} container justify="flex-end">
                          {reunion.demandante_estado === "aceptada" &&
                          reunion.oferente_estado === "aceptada" ? (
                            <Button
                              variant="outlined"
                              //          variant={boton_modal_aquiestoy.variante}
                              style={{
                                background: "#352364",
                                color: "#FFF",
                                // maxHeight: 20
                              }}
                            >
                              <a
                                href={ligaReunion(reunion.oferente_uid)}
                                style={{
                                  textDecoration: "none",
                                  color: "#FFF",
                                }}
                                rel="noreferrer"
                                target="_blank"
                              >
                                {currentLocale === "en"
                                  ? "Join the meeting"
                                  : currentLocale === "pt"
                                  ? "entrar na reunião"
                                  : "Ingresar a la reunión"}
                              </a>
                            </Button>
                          ) : null}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Hidden>
              {/** reuniones template celular fin */}
            </>
          ))}
        </Grid>
      )
    }

    const Aceptadas = () => {
      // MUESTRA LAS ACEPTADAS POR AMBOS
      var aceptadas = reunionesTodas.filter(function (el) {
        return (
          el.demandante_estado === "aceptada" &&
          el.oferente_estado === "aceptada"
        )
      })
      return <TemplateReuniones listado={aceptadas} />
    }
    const Pendientes = () => {
      // MUESTRA LAS PENDIENTES POR ALGUNO DE AMBOS
      var pendientes = reunionesTodas.filter(function (el) {
        return (
          (el.demandante_estado === "pendiente" &&
            el.oferente_estado !== "cancelada") ||
          (el.oferente_estado === "pendiente" &&
            el.demandante_estado !== "cancelada")
        )
      })
      return <TemplateReuniones listado={pendientes} />
    }
    const Canceladas = () => {
      // MUESTRA LAS canceladas POR ALGUNO DE AMBOS o rechazadas
      var canceladas = reunionesTodas.filter(function (el) {
        return (
          el.demandante_estado === "cancelada" ||
          el.oferente_estado === "cancelada" ||
          el.demandante_estado === "rechazada" ||
          el.oferente_estado === "rechazada"
        )
      })
      return <TemplateReuniones listado={canceladas} />
    }
    const Todas = () => {
      return <TemplateReuniones listado={reunionesTodas} />
    }
    return (
      <>
        <div style={{ backgroundColor: "#FFFFFF" }}>
          <Tabs
            value={botonElegidoReuniones}
            onChange={handleChangeTabsReuniones}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab
              label={
                currentLocale === "en"
                  ? boton_tab_reuniones_todas.en
                  : currentLocale === "pt"
                  ? boton_tab_reuniones_todas.pt
                  : boton_tab_reuniones_todas.es
              }
              value={"Todas"}
            />
            <Tab
              label={
                currentLocale === "en"
                  ? boton_tab_reuniones_aceptadas.en
                  : currentLocale === "pt"
                  ? boton_tab_reuniones_aceptadas.pt
                  : boton_tab_reuniones_aceptadas.es
              }
              value={"Aceptadas"}
            />
            {/*             <Tab
              label={
                currentLocale === "en"
                  ? boton_tab_reuniones_pendientes.en
                  : currentLocale === "pt"
                  ? boton_tab_reuniones_pendientes.pt
                  : boton_tab_reuniones_pendientes.es
              }
              value={"Pendientes"}
            /> */}
            <Tab
              label={
                currentLocale === "en"
                  ? boton_tab_reuniones_canceladas.en
                  : currentLocale === "pt"
                  ? boton_tab_reuniones_canceladas.pt
                  : boton_tab_reuniones_canceladas.es
              }
              value={"Canceladas"}
            />
          </Tabs>
        </div>
        <div
          style={{
            // position: "absolute",
            height: "90%",
            // width: "90%",
            // top: "10%",
            // left: "5%",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          {botonElegidoReuniones === "Todas" ? (
            <Todas />
          ) : botonElegidoReuniones === "Aceptadas" ? (
            <Aceptadas />
          ) : botonElegidoReuniones === "Pendientes" ? (
            <Pendientes />
          ) : botonElegidoReuniones === "Canceladas" ? (
            <Canceladas />
          ) : (
            ""
          )}
        </div>
      </>
    )
  }
  /*****************
   * Reuniones fin
   ****************/
  const Agenda = () => {
    return "En construcción"
    /*       <TemplateReuniones listado={aceptadas} /> */
  }

  /*** participantes inicio  */
  const Participantes = () => {
    const [empresaBusqueda, setEmpresaBusqueda] = useState(true)
    const [ofertasBusqueda, setOfertasBusqueda] = useState(false)
    const [demandasBusqueda, setDemandasBusqueda] = useState(false)
    const [paisBusqueda, setPaisBusqueda] = useState(false)

    // const [listadoFiltrado, setListadoFiltrado] = useState([{}])
    const [listadoFiltradoConstante, setListadoFiltradoConstante] =
      //  useState(listadoFiltrado)
      useState([{}])
    // const listadoFiltradoConstante = listadoFiltrado
    //useEffect si listadoFiltrado se modifica que modifique el listado de aca dentro setState
    useEffect(() => {
      setListadoFiltradoConstante(
        rowsParticipantes.sort(function (a, b) {
          let left = a.empresa
          let right = b.empresa
          return left === right ? 0 : left > right ? 1 : -1
        })
      )
    }, [rowsParticipantes])

    const [search, setSearch] = useState("")
    useEffect(() => {
      filtrado()
    }, [search])
    const handleChangeEmpresaBusqueda = event => {
      setEmpresaBusqueda(event.target.checked)
    }
    const handleChangeOfertasBusqueda = event => {
      setOfertasBusqueda(event.target.checked)
    }
    const handleChangeDemandasBusqueda = event => {
      setDemandasBusqueda(event.target.checked)
    }
    const handleChangePaisBusqueda = event => {
      setPaisBusqueda(event.target.checked)
    }

    const filtrado = () => {
      /*     setListadoFiltrado(
        rows.filter(row =>
          row.empresa.toLowerCase().includes(search.toLowerCase())
        )
      ) */
      var resultadoFiltrado = []
      rowsParticipantes.filter(function (row) {
        if (row.email !== user.email) {
          if (empresaBusqueda) {
            if (row.empresa.toLowerCase().includes(search.toLowerCase())) {
              resultadoFiltrado.push(row)
              return
            }
          }
          if (ofertasBusqueda) {
            if (row.ofertas.toLowerCase().includes(search.toLowerCase())) {
              resultadoFiltrado.push(row)
              return
            }
          }
          if (demandasBusqueda) {
            if (row.demandas.toLowerCase().includes(search.toLowerCase())) {
              resultadoFiltrado.push(row)
              return
            }
          }
          if (paisBusqueda) {
            if (row.pais.toLowerCase().includes(search.toLowerCase())) {
              resultadoFiltrado.push(row)
              return
            }
          }
        }
      })
      // setListadoFiltrado(resultadoFiltrado)
      setListadoFiltradoConstante(resultadoFiltrado)

      /*     setListadoFiltrado(
        rows.filter(function (row) {
          if (empresaBusqueda) {
            row.empresa.toLowerCase().includes(search.toLowerCase())
            return row
          }
          if (empresaBusqueda) {
            row.ofertas.toLowerCase().includes(search.toLowerCase())
          }
        })
      ) */
    }

    return (
      <>
        <Hidden xsDown>
          <div
            style={{
              width: "80%",
              position: "absolute",
              //  height: "100%",
              top: "1%",
              left: 0,
            }}
          >
            <Grid container spacing={0}>
              {listadoFiltradoConstante.map(row => (
                <Grid item xs={12} sm={12} key={row.id}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid container>
                        <Grid item xs={2}>
                          <div
                            style={{
                              //  margin: 0,
                              margin: "auto",
                              //  textAlign: "center",
                              //  alignItems: "center",
                              //maxHeight: `5vw`,
                              // height: "50%",
                              //   width: "70%",
                            }}
                          >
                            {row.logo !== "undefined" ? (
                              <CardMedia
                                className={classes.logo}
                                // image={`${row.logo}`}
                                component="img"
                                src={`${row.logo}`}
                                title={row.empresa}
                                style={{ margin: 0 }} //CAMBIO 140721
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={10}>
                          <Grid container>
                            <Grid item xs={12}>
                              <div style={{ fontSize: 22 }}>
                                {/* Nombre REP: {row.nombrerep} */}
                                {row.empresa}
                                <br />
                              </div>
                            </Grid>
                            <Grid item xs={8}>
                              {modal_ronda_registro.demandas_mostrar ? (
                                <div style={{ fontSize: 20 }}>
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      fontSize: 14,
                                    }}
                                  >
                                    {currentLocale === "en"
                                      ? modal_ronda_registro.label_demandas.en
                                      : currentLocale === "pt"
                                      ? modal_ronda_registro.label_demandas.pt
                                      : modal_ronda_registro.label_demandas
                                          .es}{" "}
                                    {row.demandas}
                                  </span>
                                  <br />
                                </div>
                              ) : (
                                ""
                              )}
                              <div style={{ fontSize: 20 }}>
                                <span
                                  style={{ fontStyle: "italic", fontSize: 14 }}
                                >
                                  {currentLocale === "en"
                                    ? modal_ronda_registro.label_ofertas.en
                                    : currentLocale === "pt"
                                    ? modal_ronda_registro.label_ofertas.pt
                                    : modal_ronda_registro.label_ofertas
                                        .es}{" "}
                                  {row.ofertas}
                                </span>
                                <br />
                              </div>
                            </Grid>
                            <Grid item xs={4} container justify="flex-end">
                              <ModalRondaElegirHorarioVisitante
                                // reunion={reunion}
                                modal_elegir_horario={modal_elegir_horario}
                                // horas_disponibles={horas_disponibles}
                                fechasUTCinscripcion={fechasUTCinscripcion}
                                row={row}
                                docRonda={docRonda}
                                // fechas={fechas}
                                reunionesTodas={reunionesTodas}
                                modal_reunirme_ronda={modal_reunirme_ronda}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <div style={{ fontSize: 20 }}>
                                <span
                                  style={{ fontStyle: "italic", fontSize: 12 }}
                                >
                                  {currentLocale === "en"
                                    ? modal_ronda_registro.label_paisesopera.en
                                    : currentLocale === "pt"
                                    ? modal_ronda_registro.label_paisesopera.pt
                                    : modal_ronda_registro.label_paisesopera
                                        .es}{" "}
                                  {row.paisesopera}
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container></Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
          <div
            style={{
              width: "19%",
              position: "absolute",
              // height: "100%",
              top: "1%",
              right: 0,
              padding: "2%",
              backgroundColor: "#fff",
            }}
          >
            <Grid container>
              <Grid item>
                <TextField
                  id="search"
                  variant="outlined"
                  onChange={e => setSearch(e.currentTarget.value)}
                  value={search}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={empresaBusqueda}
                      onChange={handleChangeEmpresaBusqueda}
                      name="empresabusqueda"
                      color="primary"
                    />
                  }
                  label={
                    currentLocale === "en"
                      ? modal_ronda_registro.label_empresa.en
                      : currentLocale === "pt"
                      ? modal_ronda_registro.label_empresa.pt
                      : modal_ronda_registro.label_empresa.es
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={paisBusqueda}
                      onChange={handleChangePaisBusqueda}
                      name="paisbusqueda"
                      color="primary"
                    />
                  }
                  label={
                    currentLocale === "en"
                      ? modal_ronda_registro.label_pais.en
                      : currentLocale === "pt"
                      ? modal_ronda_registro.label_pais.pt
                      : modal_ronda_registro.label_pais.es
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ofertasBusqueda}
                      onChange={handleChangeOfertasBusqueda}
                      name="ofertasbusqueda"
                      color="primary"
                    />
                  }
                  label={
                    currentLocale === "en"
                      ? modal_ronda_registro.label_ofertas.en
                      : currentLocale === "pt"
                      ? modal_ronda_registro.label_ofertas.pt
                      : modal_ronda_registro.label_ofertas.es
                  }
                />
                {modal_ronda_registro.demandas_mostrar ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={demandasBusqueda}
                        onChange={handleChangeDemandasBusqueda}
                        name="demandasbusqueda"
                        color="primary"
                      />
                    }
                    label={
                      currentLocale === "en"
                        ? modal_ronda_registro.label_demandas.en
                        : currentLocale === "pt"
                        ? modal_ronda_registro.label_demandas.pt
                        : modal_ronda_registro.label_demandas.es
                    }
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </div>
        </Hidden>
        {/* Version celular participantes inicio */}

        <Hidden smUp>
          <Grid container spacing={0}>
            {listadoFiltradoConstante.map(row => (
              <Grid item xs={12} sm={12} key={row.id}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <div style={{ fontSize: 22 }}>
                              {/* Nombre REP: {row.nombrerep} */}
                              {row.empresa}
                              <br />
                            </div>
                          </Grid>
                          <Grid item xs={8}>
                            {modal_ronda_registro.demandas_mostrar ? (
                              <div style={{ fontSize: 20 }}>
                                <span
                                  style={{ fontStyle: "italic", fontSize: 14 }}
                                >
                                  {currentLocale === "en"
                                    ? modal_ronda_registro.label_demandas.en
                                    : currentLocale === "pt"
                                    ? modal_ronda_registro.label_demandas.pt
                                    : modal_ronda_registro.label_demandas
                                        .es}{" "}
                                  {row.demandas}
                                </span>
                                <br />
                              </div>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid item xs={4} container justify="flex-end">
                            <ModalRondaElegirHorarioVisitante
                              // reunion={reunion}
                              modal_elegir_horario={modal_elegir_horario}
                              fechasUTCinscripcion={fechasUTCinscripcion}
                              row={row}
                              docRonda={docRonda}
                              // fechas={fechas}
                              reunionesTodas={reunionesTodas}
                              modal_reunirme_ronda={modal_reunirme_ronda}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <div style={{ fontSize: 20 }}>
                              <span
                                style={{ fontStyle: "italic", fontSize: 12 }}
                              >
                                {currentLocale === "en"
                                  ? modal_ronda_registro.label_paisesopera.en
                                  : currentLocale === "pt"
                                  ? modal_ronda_registro.label_paisesopera.pt
                                  : modal_ronda_registro.label_paisesopera
                                      .es}{" "}
                                {row.paisesopera}
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Hidden>
        {/* Version celular participantes fin*/}
      </>
    )
  }
  /***************** participantes fin   */

  /**************** codigo pais inicio  */
  const CodigoPais = pais => {
    let alfa2
    paises.filter(function (paisFiltrado) {
      if (
        paisFiltrado.nombre.es.toLowerCase().includes(pais.toLowerCase()) ||
        paisFiltrado.nombre.en.toLowerCase().includes(pais.toLowerCase()) ||
        paisFiltrado.nombre.pt.toLowerCase().includes(pais.toLowerCase())
      ) {
        if (
          paisFiltrado.nombre.es.toLowerCase() === pais.toLowerCase() ||
          paisFiltrado.nombre.en.toLowerCase() === pais.toLowerCase() ||
          paisFiltrado.nombre.pt.toLowerCase() === pais.toLowerCase()
        ) {
          alfa2 = paisFiltrado.alfa2
        }
      }
    })
    return alfa2
  }
  /**************** codigo pais fin */

  return (
    <>
      {user != null ? (
        <Layout>
          <SEO
            title={
              currentLocale === "en"
                ? nombre.en
                : currentLocale === "pt"
                ? nombre.pt
                : nombre.es
            }
          />
          <Hidden xsDown>
            <BackgroundImage
              Tag="section"
              {...bgImage}
              preserveStackingContext
              style={{ position: "fixed", top: 0 }}
              className="backimage"
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  backgroundColor: "#FFF",
                  height: "10%",
                }}
              >
                <Tabs
                  value={botonElegido}
                  onChange={handleChangeTabs}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab
                    label={
                      currentLocale === "en"
                        ? boton_tab_participantes.en
                        : currentLocale === "pt"
                        ? boton_tab_participantes.pt
                        : boton_tab_participantes.es
                    }
                    value={"Participantes"}
                  />
                  {/*             <Tab
                    label={
                      currentLocale === "en"
                        ? boton_tab_agenda.en
                        : currentLocale === "pt"
                        ? boton_tab_agenda.pt
                        : boton_tab_agenda.es
                    }
                    value={"Agenda"}
                  /> */}
                  <Tab
                    label={
                      currentLocale === "en"
                        ? boton_tab_reuniones.en
                        : currentLocale === "pt"
                        ? boton_tab_reuniones.pt
                        : boton_tab_reuniones.es
                    }
                    value={"Reuniones"}
                  />
                  <Tab
                    label={
                      currentLocale === "en"
                        ? boton_tab_miinscripcion.en
                        : currentLocale === "pt"
                        ? boton_tab_miinscripcion.pt
                        : boton_tab_miinscripcion.es
                    }
                    value={"MiInscripcion"}
                  />
                  {/*                   {docRonda.expositor && !docRonda.miinscripcion ? (
                    <Tab
                      label={
                        currentLocale === "en"
                          ? boton_tab_miinscripcion.en
                          : currentLocale === "pt"
                          ? boton_tab_miinscripcion.pt
                          : boton_tab_miinscripcion.es
                      }
                      value={"MiInscripcion"}
                    />
                  ) : docRonda.expositor && docRonda.miinscripcion ? (
                    <Tab
                      label={
                        currentLocale === "en"
                          ? "Meeting Link"
                          : currentLocale === "pt"
                          ? "Liga de reunión"
                          : "Liga de reunión"
                      }
                      value={"LigaReunion"}
                    />
                  ) : (
                    ""
                  )} */}
                  {modal_zoom.mostrar && modal_zoom.liga_reunion_unica ? (
                    <Tab
                      label={
                        currentLocale === "en"
                          ? modal_zoom.tab_zoom.en
                          : currentLocale === "pt"
                          ? modal_zoom.tab_zoom.pt
                          : modal_zoom.tab_zoom.es
                      }
                      value={"Zoom"}
                      style={{
                        background: `${modal_zoom.tab_zoom.color_fondo}`,
                        color: `${modal_zoom.tab_zoom.color_letra}`,
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Tabs>

                {/* <div className="fondoBoton">
                  <Button
                    // fullWidth
                    variant="outlined"
                    size="large"
                    onClick={() => setBotonElegido("MiInscripcion")}
                  >
                    Mi inscripción
                  </Button>
                  <Button
                    // fullWidth
                    variant="outlined"
                    size="large"
                    onClick={() => setBotonElegido("Reuniones")}
                  >
                    Reuniones
                  </Button>

                  <Button
                    // fullWidth
                    variant="outlined"
                    size="large"
                    onClick={() => setBotonElegido("Agenda")}
                  >
                    Agenda
                  </Button>

                  <Button
                    // fullWidth
                    variant="outlined"
                    size="large"
                    onClick={() => setBotonElegido("Participantes")}
                  >
                    Participantes
                  </Button>
                </div> */}
              </div>
              {(modal_ronda_registro.ronda_habilitada && !ronda_paga) ||
              (modal_ronda_registro.ronda_habilitada && docRonda.habilitado) ? (
                <div
                  style={{
                    position: "absolute",
                    height: "90%",
                    width: "96%",
                    top: "10%",
                    //  left: "0%",
                    overflow: "auto",
                    overflowX: "hidden",
                    marginRight: "2%",
                    marginLeft: "2%",
                  }}
                >
                  {botonElegido === "Participantes" ? (
                    <Participantes docRonda={docRonda} />
                  ) : botonElegido === "Agenda" ? (
                    <Agenda />
                  ) : botonElegido === "Reuniones" ? (
                    <Reuniones />
                  ) : botonElegido === "MiInscripcion" ? (
                    <MiInscripcion />
                  ) : botonElegido === "Registro" ? (
                    <Registro />
                  ) : botonElegido === "Zoom" ? (
                    <Zoom />
                  ) : (
                    /*  botonElegido === "LigaReunion" ? (
                    <LigaReunion />
                  ) : */ ""
                  )}
                </div>
              ) : botonElegido === "Registro" ? (
                <Registro />
              ) : /// inicio ronda paga
              modal_ronda_registro.ronda_habilitada && ronda_paga ? (
                <div className={classes.paper}>
                  <CssBaseline />
                  <div>
                    <Typography
                      component="h6"
                      variant="h6"
                      style={{
                        fontSize: `${ronda_paga_titulo.fuente_size}vw`,
                      }}
                    >
                      <br />
                      {currentLocale === "en"
                        ? ronda_paga_titulo.en
                        : currentLocale === "pt"
                        ? ronda_paga_titulo.pt
                        : ronda_paga_titulo.es}
                    </Typography>
                    <p>
                      {currentLocale === "en"
                        ? ronda_paga_texto.en
                        : currentLocale === "pt"
                        ? ronda_paga_texto.pt
                        : ronda_paga_texto.es}
                    </p>
                  </div>
                </div>
              ) : (
                /// fin ronda paga
                <div className={classes.paper}>
                  <CssBaseline />
                  <div>
                    <Typography
                      component="h6"
                      variant="h6"
                      style={{
                        fontSize: `${modal_ronda_registro.texto_titulo_ronda_no_habilitada.fuente_size}vw`,
                      }}
                    >
                      <br />
                      {currentLocale === "en"
                        ? modal_ronda_registro.texto_titulo_ronda_no_habilitada
                            .en
                        : currentLocale === "pt"
                        ? modal_ronda_registro.texto_titulo_ronda_no_habilitada
                            .pt
                        : modal_ronda_registro.texto_titulo_ronda_no_habilitada
                            .es}
                    </Typography>
                    <p>
                      {currentLocale === "en"
                        ? modal_ronda_registro.texto_ronda_no_habilitada.en
                        : currentLocale === "pt"
                        ? modal_ronda_registro.texto_ronda_no_habilitada.pt
                        : modal_ronda_registro.texto_ronda_no_habilitada.es}
                    </p>
                  </div>
                </div>
              )}
            </BackgroundImage>
          </Hidden>

          {/*
INICIO DE VERSION PARA CELULAR
          */}
          <Hidden smUp>
            <Grid
              container
              //     direction="column"
              //     alignItems="center"
            >
              {data.configuracion.logo_movil !== null &&
              data.configuracion.logo_movil.localFile !== null ? (
                <GatsbyImage
                  image={
                    data.configuracion.logo_movil.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt="cabecera"
                />
              ) : (
                ""
              )}
              <Grid item xs={12}>
                <div className="fondoBoton" style={{ marginTop: 0 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="outlined"
                        size="large"
                        onClick={() => setBotonElegido("MiInscripcion")}
                      >
                        {currentLocale === "en"
                          ? boton_tab_miinscripcion.en
                          : currentLocale === "pt"
                          ? boton_tab_miinscripcion.pt
                          : boton_tab_miinscripcion.es}
                      </Button>
                      {/*                  {docRonda.expositor && !docRonda.miinscripcion ? (
                        <Button
                          fullWidth
                          variant="outlined"
                          size="large"
                          onClick={() => setBotonElegido("MiInscripcion")}
                        >
                          {currentLocale === "en"
                            ? boton_tab_miinscripcion.en
                            : currentLocale === "pt"
                            ? boton_tab_miinscripcion.pt
                            : boton_tab_miinscripcion.es}
                        </Button>
                      ) : docRonda.expositor && docRonda.miinscripcion ? (
                        <Button
                          fullWidth
                          variant="outlined"
                          size="large"
                          onClick={() => setBotonElegido("LigaReunion")}
                        >
                          {currentLocale === "en"
                            ? "Meeting Link"
                            : currentLocale === "pt"
                            ? "Liga de reunión"
                            : "Liga de reunión"}
                        </Button>
                      ) : (
                        ""
                      )} */}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="outlined"
                        size="large"
                        onClick={() => setBotonElegido("Reuniones")}
                      >
                        {currentLocale === "en"
                          ? boton_tab_reuniones.en
                          : currentLocale === "pt"
                          ? boton_tab_reuniones.pt
                          : boton_tab_reuniones.es}
                      </Button>
                    </Grid>

                    {/*              <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="outlined"
                        size="large"
                        onClick={() => setBotonElegido("Agenda")}
                      >
                        {currentLocale === "en"
                          ? boton_tab_agenda.en
                          : currentLocale === "pt"
                          ? boton_tab_agenda.pt
                          : boton_tab_agenda.es}
                      </Button>
                    </Grid> */}

                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="outlined"
                        size="large"
                        onClick={() => setBotonElegido("Participantes")}
                      >
                        {currentLocale === "en"
                          ? boton_tab_participantes.en
                          : currentLocale === "pt"
                          ? boton_tab_participantes.pt
                          : boton_tab_participantes.es}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={12}>
                {botonElegido === "Participantes" ? (
                  <Participantes docRonda={docRonda} />
                ) : /* ) : botonElegido === "Agenda" ? (
                  <Agenda /> */
                botonElegido === "MiInscripcion" ? (
                  <MiInscripcion />
                ) : botonElegido === "Reuniones" ? (
                  <Reuniones />
                ) : botonElegido === "Registro" ? (
                  <Registro />
                ) : (
                  /* botonElegido === "LigaReunion" ? (
                  <LigaReunion />
                ) : */ ""
                )}
              </Grid>
            </Grid>
          </Hidden>
        </Layout>
      ) : (
        <VolverLogin />
      )}
    </>
  )
}

export default injectIntl(RondaDeNegocios)

export const query = graphql`
  query {
    configuracion: strapiConfiguracion {
      color_movil
      logo_movil {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    strapiRonda {
      ronda_paga
      ronda_paga_titulo {
        fuente_size
        es
        en
        pt
      }
      ronda_paga_texto {
        es
        en
        pt
      }
      mostrar_solo_empresas_expositoras
      modal_zoom {
        link_zoom
        mostrar
        liga_reunion_unica
        tab_zoom {
          es
          en
          pt
          color_fondo
          color_letra
        }
        texto_titulo_zoom {
          fuente_size
          es
          en
          pt
        }
        texto_contenido_zoom {
          es
          en
          pt
        }
      }
      bloquear_horarios_habilitado
      modal_ronda_registro {
        label_horarios {
          es
          en
          pt
        }
        label_liga_reunion {
          es
          en
          pt
        }
        label_representante {
          es
          en
          pt
        }
        representante_mostrar
        label_paisesopera {
          es
          en
          pt
        }
        paisesopera_mostrar
        demandas_mostrar
        registro_habilitado
        ronda_habilitada
        link_planilla
        texto_titulo_registro_cerrado {
          es
          en
          pt
          fuente_size
        }
        texto_registro_cerrado {
          es
          en
          pt
        }
        texto_titulo_ronda_no_habilitada {
          es
          en
          pt
          fuente_size
        }
        texto_ronda_no_habilitada {
          es
          en
          pt
        }
        alert_modificar_inscripcion {
          es
          en
          pt
        }
        texto_titulo_registro {
          es
          en
          pt
          fuente_size
        }
        texto_titulo_mi_inscripcion {
          es
          en
          pt
          fuente_size
        }
        boton_registro_aceptar {
          es
          en
          pt
          color_fondo
          color_letra
        }
        boton_inscripcion_aceptar {
          es
          en
          pt
          color_fondo
          color_letra
        }
        alert_campos_obligatorios {
          es
          en
          pt
        }
        label_ofertas {
          es
          en
          pt
        }
        label_demandas {
          es
          en
          pt
        }
        label_telefono {
          es
          en
          pt
        }
        label_empresa {
          es
          en
          pt
        }
        label_pais {
          es
          en
          pt
        }
        label_ciudad {
          es
          en
          pt
        }
        label_rubros {
          es
          en
          pt
        }
      }
      modal_reunirme_ronda {
        link_planilla_reuniones
        boton_solicite_reunion {
          es
          en
          pt
          color_fondo
          color_letra
        }
        boton_mas_info {
          es
          en
          pt
          color_fondo
          color_letra
        }
        texto_titulo_reunirme {
          es
          en
          pt
          fuente_size
        }
        texto_reunion_ya_solicitada {
          es
          en
          pt
          fuente_size
        }
        texto_alerta_reunion_solicitada {
          es
          en
          pt
        }
      }
      modal_modificar_estado {
        boton_modificar_estado {
          es
          en
          pt
          color_fondo
          color_letra
        }
        boton_modificar_estado_aceptar {
          es
          en
          pt
          color_fondo
          color_letra
        }
        texto_titulo_modificar_estado {
          es
          en
          pt
          fuente_size
        }
        alert_reunion_modificada {
          es
          en
          pt
        }
        label_estado_reunion {
          es
          en
          pt
        }
      }
      fechas {
        id
        dia
        horas {
          id
          hora
        }
      }
      nombre {
        es
        en
        pt
      }
      modal_elegir_horario {
        boton_confirmar_hora_aceptar {
          es
          en
          pt
          color_fondo
          color_letra
        }
        boton_confirmar_hora {
          es
          en
          pt
          color_fondo
          color_letra
        }
        texto_titulo_confirmar_hora {
          es
          en
          pt
          fuente_size
        }
        alert_elegir_hora {
          es
          en
          pt
        }
        texto_elegir_horas_disponibles {
          es
          en
          pt
          fuente_size
        }
        texto_estado_reunion {
          es
          en
          pt
          fuente_size
        }
        alert_estado_reunion {
          es
          en
          pt
        }
        alert_reunion_confirmada {
          es
          en
          pt
        }
      }
      boton_tab_participantes {
        es
        en
        pt
      }
      boton_tab_agenda {
        es
        en
        pt
      }
      boton_tab_reuniones {
        es
        en
        pt
      }
      boton_tab_miinscripcion {
        es
        en
        pt
      }
      boton_tab_reuniones_todas {
        es
        en
        pt
      }
      boton_tab_reuniones_aceptadas {
        es
        en
        pt
      }
      boton_tab_reuniones_pendientes {
        es
        en
        pt
      }
      boton_tab_reuniones_canceladas {
        es
        en
        pt
      }
      imagen_fondo {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
