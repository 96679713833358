import React, { useState, useEffect, useContext } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import firebase from "gatsby-plugin-firebase"
import { AuthContext } from "../../context/auth"
import {
  Modal,
  Button,
  CssBaseline,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
  MenuItem,
  Hidden,
  Select,
  InputLabel,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "80%",
    left: "10%",
    top: "5%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const ModalRondaModificarEstadoReunion = ({
  intl,
  reunion,
  modal_modificar_estado,
}) => {
  const { user } = useContext(AuthContext)
  const classes = useStyles()
  const currentLocale = intl.locale
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [open, setOpen] = useState(false)
  const [estadoReunion, setEstadoReunion] = useState("") // ver que default le paso, y ambos pueden cambiar su estado?
  const handleChangeEstadoReunion = event => {
    setEstadoReunion(event.target.value)
  }
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  // console.log("reunion data", reunion)

  const handleSubmit = e => {
    e.preventDefault()

    //pasar el soy a reuniones ronda
    // actualizar ambos documentos de rondadenegocios, oferente_uid demandante_uid es el documento

    // solo deje modificar estado de reunion si ya fue aceptada y se mantiene asi por ambos para poder borrar la hora seguro
    //de ambos,

    //actualiza el estado de la reunion demandante
    if (estadoReunion === "rechazada" || estadoReunion === "cancelada") {
    setBtnDisabled(true)
      firebase
        .firestore()
        .collection("rondadenegocios")
        .doc(reunion.demandante_uid)
        .update({
          [`horas.${reunion.hora_reunion}`]: estadoReunion,
        })
        .then(function () {
          //actualiza el estado de la reunion oferente
          firebase
            .firestore()
            .collection("rondadenegocios")
            .doc(reunion.oferente_uid)
            .update({
              [`horas.${reunion.hora_reunion}`]: estadoReunion,
            })
            .then(function () {
              //actualiza el estado de la reunion
              reunion.demandante_uid === user.uid 
                ? firebase
                    .firestore()
                    .collection("reunionesronda")
                    .doc(reunion.doc_id)
                    .update({
                      demandante_estado: estadoReunion,
                    })
                    .then(function () {
                      setOpen(false)
                      alert(
                        currentLocale === "en"
                          ? modal_modificar_estado.alert_reunion_modificada.en
                          : currentLocale === "pt"
                          ? modal_modificar_estado.alert_reunion_modificada.pt
                          : modal_modificar_estado.alert_reunion_modificada.es
                      )
                    })
                : firebase
                    .firestore()
                    .collection("reunionesronda")
                    .doc(reunion.doc_id)
                    .update({
                      oferente_estado: estadoReunion,
                    })
                    .then(function () {
                      setOpen(false)
                      alert(
                        currentLocale === "en"
                          ? modal_modificar_estado.alert_reunion_modificada.en
                          : currentLocale === "pt"
                          ? modal_modificar_estado.alert_reunion_modificada.pt
                          : modal_modificar_estado.alert_reunion_modificada.es
                      )
                      setEstadoReunion("")
                    })
            })
        })
    } else {
      setOpen(false)
    }
  }

  /**
   * agregar a strapi este modal con todas sus cualidades
   *
   */

  const body = (
    <div className={classes.paper}>
      <CssBaseline />
      <Hidden xsDown>
        <Typography
          component="h5"
          variant="h5"
          style={{
            fontSize: `${modal_modificar_estado.texto_titulo_modificar_estado.fuente_size}vw`,
          }}
        >
          {currentLocale === "en"
            ? modal_modificar_estado.texto_titulo_modificar_estado.en
            : currentLocale === "pt"
            ? modal_modificar_estado.texto_titulo_modificar_estado.pt
            : modal_modificar_estado.texto_titulo_modificar_estado.es}
        </Typography>
      </Hidden>
      <Hidden smUp>
        <Typography component="p" variant="p">
          {currentLocale === "en"
            ? modal_modificar_estado.texto_titulo_modificar_estado.en
            : currentLocale === "pt"
            ? modal_modificar_estado.texto_titulo_modificar_estado.pt
            : modal_modificar_estado.texto_titulo_modificar_estado.es}
        </Typography>
      </Hidden>

      <br />
      <hr />
      <InputLabel id="estadoLabel" style={{ padding: 5 }}>
        {currentLocale === "en"
          ? modal_modificar_estado.label_estado_reunion.en
          : currentLocale === "pt"
          ? modal_modificar_estado.label_estado_reunion.pt
          : modal_modificar_estado.label_estado_reunion.es}
      </InputLabel>
      <Select
        style={{ width: "100%", padding: 5 }}
        id="estado"
        value={estadoReunion}
        onChange={handleChangeEstadoReunion}
      >
        {/*  <MenuItem value="aceptada">Aceptada</MenuItem> */}
     {/*    <MenuItem value="rechazada">Rechazada</MenuItem> */}
        <MenuItem value="cancelada"> {currentLocale === "en"
          ? "Cancelled"
          : currentLocale === "pt"
          ? "Cancelado"
          : "Cancelada"}</MenuItem>
      </Select>
      <form noValidate onSubmit={handleSubmit}>
        <Button
          type="submit"
          fullWidth
          disabled={btnDisabled}
          variant="outlined"
          style={{
            marginTop: 20,
            background: `${modal_modificar_estado.boton_modificar_estado_aceptar.color_fondo}`,
            color: `${modal_modificar_estado.boton_modificar_estado_aceptar.color_letra}`,
          }}
        >
          {currentLocale === "en"
            ? modal_modificar_estado.boton_modificar_estado_aceptar.en
            : currentLocale === "pt"
            ? modal_modificar_estado.boton_modificar_estado_aceptar.pt
            : modal_modificar_estado.boton_modificar_estado_aceptar.es}
        </Button>
      </form>
      {/*       <div style={{ margin: 0, textAlign: "center" }}>
logo oferente
        <img
          // src={`data:image/png;base64,${imagenElegida}`}
          src={`${row.logo}`}
          width="150"
          alt="logo"
          style={{ margin: 0 }}
        />
        <br />

        <br />

        <Typography noWrap variant="h6" component="h6">
          {row.empresa}
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          <span style={{ fontStyle: "italic", fontSize: 12 }}>{row.ramo}</span>
        </Typography>
      </div>
      <br />

      <Typography variant="body2" component="p">
        <span style={{ fontWeight: "bold" }}>Ofertas: </span>
        <span style={{ fontStyle: "italic" }}>{row.ofertas}</span>
      </Typography>
      <br />

      <Typography variant="body2" component="p" gutterBottom>
        <span style={{ fontWeight: "bold" }}>Demandas: </span>
        <span style={{ fontStyle: "italic" }}>{row.demandas}</span>
      </Typography>
      <br />

      <br />
      <div style={{ margin: 0, textAlign: "center" }}>
        <img
          src={`https://flagcdn.com/${row.codpais}.svg`}
          width="30"
          alt={row.pais}
          title={row.pais}
          style={{ margin: 0 }}
        />
      </div>

      <form noValidate onSubmit={handleSubmit}>
      {reunionYaSolicitada ? (
        "Pendiente"
      ) : (<Button
          type="submit"
          fullWidth
          disabled={btnDisabled}
          variant="outlined"
          style={{
            marginTop: 20,
            background: `${colorBackground}`,
            color: `${colorBoton}`,
          }}
        >
          Solicitar reunión
        </Button>)}
      </form> */}
    </div>
  )

  return (
    <div>
      {/*  <Tooltip title="CAMBIAR" arrow placement="top"> */}
      {reunion.oferente_estado === "aceptada" &&
      reunion.demandante_estado === "aceptada" ? ( //SI AMBOS TIENEN ACEPTADA SE PUEDE MODIFICAR ESTADO
        <Button
          //  fullWidth
          variant="outlined"
          //          variant={boton_modal_aquiestoy.variante}
          style={{
            background: `${modal_modificar_estado.boton_modificar_estado.color_fondo}`,
            color: `${modal_modificar_estado.boton_modificar_estado.color_letra}`,
            // maxHeight: 20
          }}
          //size={boton_modal_aquiestoy.size}
          onClick={handleOpen}
          //  size="small"
        >
          {currentLocale === "en"
            ? modal_modificar_estado.boton_modificar_estado.en
            : currentLocale === "pt"
            ? modal_modificar_estado.boton_modificar_estado.pt
            : modal_modificar_estado.boton_modificar_estado.es}
        </Button>
      ) : (
        ""
      )}
      {/*  </Tooltip> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ overflow: "scroll" }}
      >
        {body}
      </Modal>
    </div>
  )
}

export default injectIntl(ModalRondaModificarEstadoReunion)
