import React, { useState, useEffect } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import logodefault from "./logodefault"
import firebase from "gatsby-plugin-firebase"
import { StaticImage } from "gatsby-plugin-image"
import Resizer from "react-image-file-resizer"

import {
  Modal,
  Button,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  makeStyles,
  FormControlLabel,
  Switch,
  InputLabel,
  Hidden,
  MenuItem,
  Select,
} from "@material-ui/core"
import usePaises from "../../hooks/usePaises"

import axios from "axios"

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "80%",
    left: "10%",
    top: "5%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))
const ModalRegistroRonda = ({
  intl,
  modal_ronda_registro,
  fechasUTCinscripcion,
  user,
  setDocRonda,
  setBotonElegido,
  bloquear_horarios_habilitado,
  modal_zoom,
}) => {
  const classes = useStyles()
  const currentLocale = intl.locale

  const [imagenElegida, setImagenElegida] = useState("")
  const [pais, setPais] = useState("")
  const paises = usePaises()

  const handleChangePais = event => {
    setPais(event.target.value)
  }
  const [ramo, setRamo] = useState("")
  const [segmentoEmpresa, setSegmentoEmpresa] = useState("")
  const handleChangeSegmentoEmpresa = event => {
    setSegmentoEmpresa(event.target.value)
  }

  const ramos_es = [
    "Industria",
    "Automotor",
    "Aviación y aeroespacial",
    "Materiales de construcción y construcción",
    "Tecnología limpia",
    "Bienes de consumo",
    "Defender y Homeland Security",
    "Educación",
    "Servicio Financiero y Profesional",
    "Alimentos / Agricultura",
    "Tecnologías de la información",
    "Logística, distribución e infraestructura",
    "Ciencias de la vida y tecnología médica",
    "Fabricación",
    "Embarcaciones y equipos marinos",
    "Otro",
  ]
  const ramos_en = [
    "Industry",
    "Automotive",
    "Aviation and aerospace",
    "Building and construction materials",
    "Clean technology",
    "Consumer goods",
    "Defender and Homeland Security",
    "Education",
    "Financial and Professional Service",
    "Food / Agriculture",
    "Information technology",
    "Logistics, distribution, and infrastructure",
    "Life Sciences and Medical Technology",
    "Manufacturing",
    "Marine vessels and equipment",
    "Other",
  ]
  const ramos_pt = [
    "Indústria",
    "Automotivo",
    "Aviação e aeroespacial",
    "Construção e materiais de construção",
    "Tecnologia limpa",
    "Bens de consumo",
    "Defender e Segurança Interna",
    "Educação",
    "Serviço Financeiro e Profissional",
    "Alimentos / Agricultura",
    "Tecnologia da Informação",
    "Logística, distribuição e infraestrutura",
    "Ciências da Vida e Tecnologia Médica",
    "Manufatura",
    "Embarcações e equipamentos marítimos",
    "Outro",
  ]
  const CodigoPais = pais => {
    let alfa2
    paises.filter(function (paisFiltrado) {
      if (
        paisFiltrado.nombre.es.toLowerCase().includes(pais.toLowerCase()) ||
        paisFiltrado.nombre.en.toLowerCase().includes(pais.toLowerCase()) ||
        paisFiltrado.nombre.pt.toLowerCase().includes(pais.toLowerCase())
      ) {
        if (
          paisFiltrado.nombre.es.toLowerCase() === pais.toLowerCase() ||
          paisFiltrado.nombre.en.toLowerCase() === pais.toLowerCase() ||
          paisFiltrado.nombre.pt.toLowerCase() === pais.toLowerCase()
        ) {
          alfa2 = paisFiltrado.alfa2
        }
      }
    })
    return alfa2
  }
  /**************** codigo pais fin */

  useEffect(() => {
    firebase
      .firestore()
      .collection("usuarios")
      .doc(user.uid)
      .get()
      .then(doc => {
        if (doc.exists) {
          setPais(doc.data().pais)
          setRamo(doc.data().ramo)
          //   setImagenElegida(doc.data().logo)
          //setSegmentoEmpresa(localStorage.getItem("segmentoempresa"))
          setDataRegistroRonda({
            ...dataRegistroRonda,
            email: doc.data().email,
            nombre: doc.data().nombre,
            apellido: doc.data().apellido,
            telefono: doc.data().telefono,
            profesion: doc.data().profesion,
            //   empresa: doc.getItem("empresa"),
            //   cargo: doc.getItem("cargo"),
            //   whatsapp: doc.getItem("whatsapp"),
            //   ciudad: doc.getItem("ciudad"),
            //   instagram: doc.getItem("instagram"),
            //   linkedin: doc.getItem("linkedin"),
            //   sitioweb: doc.getItem("sitioweb"),
          })
        }
      })
    /* 
      setData({
        //   registradoRonda: localStorage.getItem("registradoRonda"), // si esta no pedir datos
        email: localStorage.getItem("email"),
        nombre: localStorage.getItem("nombre"),
        apellido: localStorage.getItem("apellido"),
        telefono: localStorage.getItem("telefono"),
        empresa:
          localStorage.getItem("empresa") === "undefined"
            ? ""
            : localStorage.getItem("empresa"),
        ciudad:
          localStorage.getItem("ciudad") === "undefined"
            ? ""
            : localStorage.getItem("ciudad"),
      }) */
  }, [])

  const [open, setOpen] = useState(true) // PASAR A FALSE SI NO QUIERO ABRA AUTOMATICAMENTE

  const handleClose = () => {
    //     setRegistrado(true)
    //     setOpen(false)
  }

  const handleChange = e => {
    setDataRegistroRonda({
      ...dataRegistroRonda,
      [e.target.name]: e.target.value,
    })
  }
  const [dataRegistroRonda, setDataRegistroRonda] = useState({
    // si ya mando oferta y demanda otro boolean para que no pueda de nuevo???? pero solo para esta ronda
    //que campo para ronda registrado? que el campo coincida con la ronda en curso, si es el num de una
    //vieja que le pida de nuevo oferta y demanda y no deje ver hasta que haga eso
    email: "",
    ofertas: "",
    telefono: "", //cambiar a telefono o usar whatsapp?
    demandas: "",
    //  tiposolucion: "",
    //  nombre: "",
    // apellido: "",
    empresa: "",
    //  pais: localStorage.getItem("pais"),
    ciudad: "",
    // rubro: localStorage.getItem("ramo"),
    //logo: imagenElegida, //
    // descripcion: "",
  })
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [dataHoras, setDataHoras] = useState(fechasUTCinscripcion)

  const fileChangedHandler = event => {
    var fileInput = false
    if (event.target.files[0]) {
      fileInput = true
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0], //file
          200, //maxWidth
          200, //maxHeight
          "PNG", // compressFormat Can be either JPEG, PNG or WEBP.
          80, // quality
          0, //rotation
          uri => {
            //responseUriFunc Callback function of URI. Returns URI of resized image's base64 format. ex: uri => {console.log(uri)});
            setImagenElegida(uri)
          },
          "base64", //outputType Can be either base64, blob or file.(Default type is base64)
          100, //minWidth
          100 //minHeight
        )
      } catch (err) {
        console.log(err)
      }
    }
  }
  const handleSubmit = e => {
    e.preventDefault()
    setDataRegistroRonda({ ...dataRegistroRonda })
    if (
      // true
      dataRegistroRonda.ofertas !== "" &&
      // dataRegistroRonda.demandas !== "" &&
      dataRegistroRonda.email !== "" &&
      dataRegistroRonda.empresa !== "" &&
      dataRegistroRonda.ciudad !== "" &&
      dataRegistroRonda.telefono !== "" &&
      pais !== ""
    ) {
      setBtnDisabled(true)

      firebase // ACA PONER BOOLEANOS QUE SI ES RONDA MATCHEO GRABA OFERTA DEMANDA O VER QUE SINO LO QUE NO GRABA
        .firestore()
        .collection("rondadenegocios")
        .doc(user.uid)
        .set({
          nombre: dataRegistroRonda.nombre,
          apellido: dataRegistroRonda.apellido,
          email: dataRegistroRonda.email,
          ofertas: dataRegistroRonda.ofertas,
          telefono: dataRegistroRonda.telefono,
          demandas: dataRegistroRonda.demandas,
          empresa: dataRegistroRonda.empresa,
          //segmentoempresa: segmentoEmpresa,
          // tiposolucion: "",
          pais: pais,
          codpais: CodigoPais(pais),
          ciudad: dataRegistroRonda.ciudad,
          ramo: ramo,
          logo: imagenElegida !== "" ? imagenElegida : logodefault(),
          uid: user.uid,
          horas: dataHoras, // aca le meto las horas que elija
          nombrerep: dataRegistroRonda.nombrerep,
          paisesopera: dataRegistroRonda.paisesopera,
          ligareunion: dataRegistroRonda.ligareunion,
          //  miinscripcion: false,
        })
      setDocRonda({
        nombre: dataRegistroRonda.nombre,
        apellido: dataRegistroRonda.apellido,
        email: dataRegistroRonda.email,
        ofertas: dataRegistroRonda.ofertas,
        telefono: dataRegistroRonda.telefono,
        demandas: dataRegistroRonda.demandas,
        // tiposolucion: "",
        // segmentoempresa: segmentoEmpresa,
        nombrerep: dataRegistroRonda.nombrerep,
        paisesopera: dataRegistroRonda.paisesopera,
        ligareunion: dataRegistroRonda.ligareunion,
        empresa: dataRegistroRonda.empresa,
        pais: pais,
        codpais: CodigoPais(pais),
        ciudad: dataRegistroRonda.ciudad,
        ramo: ramo,
        logo: imagenElegida !== "" ? imagenElegida : logodefault(),
        uid: user.uid,
        horas: dataHoras, // aca le meto las horas que elija
        //  miinscripcion: false,
      })
      // let today = new Date.UTC()

      axios.post(modal_ronda_registro.link_planilla, {
        email: dataRegistroRonda.email,
        nombre: dataRegistroRonda.nombre,
        apellido: dataRegistroRonda.apellido,
        empresa: dataRegistroRonda.empresa,
        ofertas: dataRegistroRonda.ofertas,
        demandas: dataRegistroRonda.demandas,
        telefono: dataRegistroRonda.telefono,
        pais: pais,
        ciudad: dataRegistroRonda.ciudad,
        nombrerep: dataRegistroRonda.nombrerep,
        paisesopera: dataRegistroRonda.paisesopera,
        ligareunion: dataRegistroRonda.ligareunion,
        rubro: ramo,
        fecha: Date.now().toString(), // es en epoch UTC
      })

      setBotonElegido("Participantes")
      setOpen(false)
    } else {
      alert(
        currentLocale === "en"
          ? modal_ronda_registro.alert_campos_obligatorios.en
          : currentLocale === "pt"
          ? modal_ronda_registro.alert_campos_obligatorios.pt
          : modal_ronda_registro.alert_campos_obligatorios.es
      )
    }
  }

  const ramos =
    currentLocale === "en"
      ? ramos_en
      : currentLocale === "pt"
      ? ramos_pt
      : ramos_es
  const handleChangeRamo = event => {
    setRamo(event.target.value)
  }
  const checkDataHoras = hora => {
    for (let key in dataHoras) {
      if (hora === key) {
        return dataHoras[key] === "libre" ? true : false
      }
    }
  }
  const handleChangeHorasOcupado = event => {
    setDataHoras({
      ...dataHoras,
      [event.target.name]: event.target.checked ? "libre" : "ocupado",
    })
    // setDataHoras({ ...dataHoras, [event.target.name]: event.target.checked })
  }
  const ListaHoras = () => {
    let aaa = []
    for (let key in fechasUTCinscripcion) {
      //  console.log("estado", fechasUTCinscripcion[key], "/ key epoch ", key)
      let fechita = new Date(parseInt(key))
      // aaa.push(<h4>{fechita.toLocaleString()} : estado: {fechasUTCinscripcion[key]}</h4>)
      aaa.push(
        <Grid item sm={3}>
          <FormControlLabel
            control={
              <Switch
                checked={checkDataHoras(key)}
                //checked={true}
                onChange={handleChangeHorasOcupado}
                name={key}
                color="primary"
              />
            }
            //  label={checkDataHoras(key)}
            label={fechita.toLocaleString()}
          />
        </Grid>
      )
    }
    return aaa
  }

  const body = (
    <div className={classes.paper}>
      <CssBaseline />
      {modal_ronda_registro.registro_habilitado ? (
        <div>
          <Hidden xsDown>
            <Typography
              component="h6"
              variant="h6"
              style={{
                fontSize: `${modal_ronda_registro.texto_titulo_registro.fuente_size}vw`,
              }}
            >
              <br />
              {currentLocale === "en"
                ? modal_ronda_registro.texto_titulo_registro.en
                : currentLocale === "pt"
                ? modal_ronda_registro.texto_titulo_registro.pt
                : modal_ronda_registro.texto_titulo_registro.es}
            </Typography>
          </Hidden>
          <Hidden smUp>
            <Typography component="p" variant="p">
              <br />
              {currentLocale === "en"
                ? modal_ronda_registro.texto_titulo_registro.en
                : currentLocale === "pt"
                ? modal_ronda_registro.texto_titulo_registro.pt
                : modal_ronda_registro.texto_titulo_registro.es}
            </Typography>
          </Hidden>

          <form noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="ofertas"
              label={
                currentLocale === "en"
                  ? modal_ronda_registro.label_ofertas.en
                  : currentLocale === "pt"
                  ? modal_ronda_registro.label_ofertas.pt
                  : modal_ronda_registro.label_ofertas.es
              }
              type="text"
              id="ofertas"
              value={dataRegistroRonda.ofertas}
              onChange={handleChange}
            />
            {modal_ronda_registro.demandas_mostrar ? (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="demandas"
                label={
                  currentLocale === "en"
                    ? modal_ronda_registro.label_demandas.en
                    : currentLocale === "pt"
                    ? modal_ronda_registro.label_demandas.pt
                    : modal_ronda_registro.label_demandas.es
                }
                type="text"
                id="demandas"
                value={dataRegistroRonda.demandas}
                onChange={handleChange}
              />
            ) : (
              ""
            )}
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="telefono"
              label={
                currentLocale === "en"
                  ? modal_ronda_registro.label_telefono.en
                  : currentLocale === "pt"
                  ? modal_ronda_registro.label_telefono.pt
                  : modal_ronda_registro.label_telefono.es
              }
              type="tel"
              id="telefono"
              value={dataRegistroRonda.telefono}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="empresa"
              label={
                currentLocale === "en"
                  ? modal_ronda_registro.label_empresa.en
                  : currentLocale === "pt"
                  ? modal_ronda_registro.label_empresa.pt
                  : modal_ronda_registro.label_empresa.es
              }
              type="text"
              id="empresa"
              value={dataRegistroRonda.empresa}
              onChange={handleChange}
            />

            <InputLabel id="paisLabel" style={{ padding: 5 }}>
              {currentLocale === "en"
                ? modal_ronda_registro.label_pais.en
                : currentLocale === "pt"
                ? modal_ronda_registro.label_pais.pt
                : modal_ronda_registro.label_pais.es}
            </InputLabel>
            <Select
              style={{ width: "100%", padding: 5 }}
              id="pais"
              value={pais}
              onChange={handleChangePais}
            >
              {paises.map(pais => (
                <MenuItem value={pais.nombre.es}>
                  {pais.mostrar ? (
                    <>
                      {currentLocale === "en"
                        ? pais.nombre.en
                        : currentLocale === "pt"
                        ? pais.nombre.pt
                        : pais.nombre.es}
                    </>
                  ) : (
                    ""
                  )}
                </MenuItem>
              ))}
            </Select>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="ciudad"
              label={
                currentLocale === "en"
                  ? modal_ronda_registro.label_ciudad.en
                  : currentLocale === "pt"
                  ? modal_ronda_registro.label_ciudad.pt
                  : modal_ronda_registro.label_ciudad.es
              }
              type="text"
              id="ciudad"
              value={dataRegistroRonda.ciudad}
              onChange={handleChange}
            />

            <InputLabel id="ramosLabel" style={{ padding: 5 }}>
              {currentLocale === "en"
                ? modal_ronda_registro.label_rubros.en
                : currentLocale === "pt"
                ? modal_ronda_registro.label_rubros.pt
                : modal_ronda_registro.label_rubros.es}
            </InputLabel>
            <Select
              style={{ width: "100%", padding: 5 }}
              id="categoria"
              value={ramo}
              onChange={handleChangeRamo}
            >
              {ramos.map(ramo => (
                <MenuItem value={ramo}>{ramo}</MenuItem>
              ))}
            </Select>
            <div>&nbsp;</div>
            <div style={{ textAlign: "center" }}>
              {imagenElegida === null ||
              imagenElegida === "undefined" ||
              imagenElegida === "" ? (
                <Button component="label">
                  {currentLocale === "en" ? (
                    <StaticImage
                      src="../../images/subir_imagen_en.png"
                      alt="Subir Imagen"
                    />
                  ) : currentLocale === "pt" ? (
                    <StaticImage
                      src="../../images/subir_imagen_pt.png"
                      alt="Subir Imagen"
                    />
                  ) : (
                    <StaticImage
                      src="../../images/subir_imagen.png"
                      alt="Subir Imagen"
                    />
                  )}

                  <input type="file" hidden onChange={fileChangedHandler} />
                </Button>
              ) : (
                <Button component="label">
                  <img src={`${imagenElegida}`} alt="" />

                  <input type="file" hidden onChange={fileChangedHandler} />
                </Button>
              )}
            </div>
            {modal_ronda_registro.paisesopera_mostrar ? (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="paisesopera"
                label={
                  currentLocale === "en"
                    ? modal_ronda_registro.label_paisesopera.en
                    : currentLocale === "pt"
                    ? modal_ronda_registro.label_paisesopera.pt
                    : modal_ronda_registro.label_paisesopera.es
                }
                type="text"
                id="paisesopera"
                value={dataRegistroRonda.paisesopera}
                onChange={handleChange}
              />
            ) : (
              ""
            )}
            {modal_ronda_registro.representante_mostrar ? (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="nombrerep"
                label={
                  currentLocale === "en"
                    ? modal_ronda_registro.label_representante.en
                    : currentLocale === "pt"
                    ? modal_ronda_registro.label_representante.pt
                    : modal_ronda_registro.label_representante.es
                }
                type="text"
                id="nombrerep"
                value={dataRegistroRonda.nombrerep}
                onChange={handleChange}
              />
            ) : (
              ""
            )}

            {modal_zoom.liga_reunion_unica ? (
              ""
            ) : (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="ligareunion"
                label={
                  currentLocale === "en"
                    ? modal_ronda_registro.label_liga_reunion.en
                    : currentLocale === "pt"
                    ? modal_ronda_registro.label_liga_reunion.pt
                    : modal_ronda_registro.label_liga_reunion.es
                }
                type="text"
                id="ligareunion"
                value={dataRegistroRonda.ligareunion}
                onChange={handleChange}
              />
            )}

            {bloquear_horarios_habilitado ? (
              <>
                <InputLabel id="horas" style={{ padding: 15 }}>
                  {currentLocale === "en"
                    ? modal_ronda_registro.label_horarios.en
                    : currentLocale === "pt"
                    ? modal_ronda_registro.label_horarios.pt
                    : modal_ronda_registro.label_horarios.es}
                </InputLabel>
                <Grid container>
                  <ListaHoras />
                </Grid>
              </>
            ) : (
              ""
            )}

            <Button
              type="submit"
              fullWidth
              disabled={btnDisabled}
              // variant={boton_modal_inscripcion.variante}
              style={{
                background: `${modal_ronda_registro.boton_registro_aceptar.color_fondo}`,
                color: `${modal_ronda_registro.boton_registro_aceptar.color_letra}`,
                marginTop: 20,
              }}
              //   size={boton_modal_inscripcion.size}
            >
              {currentLocale === "en"
                ? modal_ronda_registro.boton_registro_aceptar.en
                : currentLocale === "pt"
                ? modal_ronda_registro.boton_registro_aceptar.pt
                : modal_ronda_registro.boton_registro_aceptar.es}
            </Button>
          </form>
        </div>
      ) : (
        <div>
          <Typography
            component="h6"
            variant="h6"
            style={{
              fontSize: `${modal_ronda_registro.texto_titulo_registro_cerrado.fuente_size}vw`,
            }}
          >
            <br />
            {currentLocale === "en"
              ? modal_ronda_registro.texto_titulo_registro_cerrado.en
              : currentLocale === "pt"
              ? modal_ronda_registro.texto_titulo_registro_cerrado.pt
              : modal_ronda_registro.texto_titulo_registro_cerrado.es}
          </Typography>
          <p>
            {currentLocale === "en"
              ? modal_ronda_registro.texto_registro_cerrado.en
              : currentLocale === "pt"
              ? modal_ronda_registro.texto_registro_cerrado.pt
              : modal_ronda_registro.texto_registro_cerrado.es}
          </p>
        </div>
      )}
    </div>
  )

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ overflow: "scroll" }}
      >
        {body}
      </Modal>
    </div>
  )
}

export default injectIntl(ModalRegistroRonda)
