import React, { useState, useEffect } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import firebase from "gatsby-plugin-firebase"
import {
  Modal,
  Button,
  CssBaseline,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
  InputLabel,
  Hidden,
  MenuItem,
  Select,
} from "@material-ui/core"

import axios from "axios"

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "80%",
    left: "10%",
    top: "5%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))
const ModalRondaElegirHorarioVisitante = ({
  intl,
  //  reunion,
  modal_elegir_horario,
  fechasUTCinscripcion,
  row,
  docRonda,
  modal_reunirme_ronda,
  reunionesTodas,
}) => {
  const {
    texto_titulo_confirmar_hora,
    boton_confirmar_hora,
    boton_confirmar_hora_aceptar,
    alert_elegir_hora,
    texto_elegir_horas_disponibles,
    texto_estado_reunion,
    alert_reunion_confirmada,
    alert_estado_reunion,
  } = modal_elegir_horario
  const classes = useStyles()
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [open, setOpen] = useState(false)
  // const [reunionYaSolicitada, setReunionYaSolicitada] = useState(false)
  const [estadoReunion, setEstadoReunion] = useState("aceptada")
  /*   const handleChangeEstadoReunion = event => {
    setEstadoReunion(event.target.value)
  } */
  const [horaReunion, setHoraReunion] = useState("") // ver que default le paso, y ambos pueden cambiar su estado?
  const handleChangeHoraReunion = event => {
    setHoraReunion(event.target.value)
  }
  const [docOferente, setDocOferente] = useState({})
  const [docDemandante, setDocDemandante] = useState({})
  const [reunionYaSolicitada, setReunionYaSolicitada] = useState(false)
  useEffect(() => {
    reunionesTodas.forEach(function (reunion) {
      if (
        (docRonda.uid === reunion.demandante_uid &&
          row.uid === reunion.oferente_uid) ||
        (row.uid === reunion.demandante_uid &&
          docRonda.uid === reunion.oferente_uid)
      ) {
        if (
          reunion.oferente_estado === "aceptada" &&
          reunion.demandante_estado === "aceptada"
        ) {
          setReunionYaSolicitada(true)
        }
      }
    })
  }, [])
  const [horasDisponibles, setHorasDisponibles] = useState([])

  useEffect(() => {
    if (docOferente.horas !== undefined && docDemandante.horas !== undefined) {
      const horaAgregarArray = []

      for (let key in fechasUTCinscripcion) {
        //  console.log("estado", fechasUTCinscripcion[key], "/ key epoch ", key)
        // let fechita = new Date(parseInt(key))
        // aaa.push(<h4>{fechita.toLocaleString()} : estado: {fechasUTCinscripcion[key]}</h4>)
        if (
          docOferente.horas[key] !== "aceptada" &&
          docOferente.horas[key] !== "ocupado"
        ) {
          if (
            docDemandante.horas[key] !== "aceptada" &&
            docDemandante.horas[key] !== "ocupado"
          ) {
            horaAgregarArray.push(key)
          }
        }
      }

      setHorasDisponibles(horaAgregarArray)
    }
  }, [docDemandante, docOferente, open]) //CAMBIO 140721 agregue open asi cuando se abre o cierra actualiza horas disp

  //console.log("oferente row", row)

  useEffect(() => {
    var unsuscribe = firebase
      .firestore()
      .collection("rondadenegocios")
      .doc(row.uid)
      .onSnapshot(
        snapshot => {
          // .get()
          // .then(doc => {
          if (snapshot.exists) {
            setDocOferente(snapshot.data())
          } else {
            //  setBotonElegido("Registro")
          }
        },
        error => {
          console.log("Error getting documents: ", error)
        }
      )
    return function cleanup() {
      unsuscribe()
    }
  }, [])

  useEffect(() => {
    var unsuscribeDemandante = firebase
      .firestore()
      .collection("rondadenegocios")
      .doc(docRonda.uid)
      .onSnapshot(
        snapshot => {
          if (snapshot.exists) {
            setDocDemandante(snapshot.data())
          } else {
            //setBotonElegido("Registro")
          }
        },
        error => {
          console.log("Error getting documents: ", error)
        }
      )
    return function cleanup() {
      unsuscribeDemandante()
    }
  }, [])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (horaReunion !== "") {
      setBtnDisabled(true)
      firebase
        .firestore()
        .collection("reunionesronda")
        .add({
          demandante_email: docRonda.email,
          demandante_nombrerep: docRonda.nombrerep,
          demandante_paisesopera: docRonda.paisesopera,
          demandante_nombre: docRonda.nombre,
          demandante_apellido: docRonda.apellido,
          // demandante_segmentoempresa: docRonda.segmentoempresa,
          //demandante_ligareunion: docRonda.ligareunion,
          demandante_ofertas: docRonda.ofertas,
          demandante_telefono: docRonda.telefono,
          demandante_demandas: docRonda.demandas,
          demandante_empresa: docRonda.empresa,
          demandante_pais: docRonda.pais,
          demandante_codpais: docRonda.codpais,
          demandante_ciudad: docRonda.ciudad,
          demandante_ramo: docRonda.ramo,
          demandante_logo: docRonda.logo,
          demandante_uid: docRonda.uid,
          demandante_estado: "aceptada",
          oferente_email: row.email,
          oferente_nombrerep: row.nombrerep,
          oferente_paisesopera: row.paisesopera,
          // oferente_tiposolucion: row.tiposolucion,
          // oferente_segmentoempresa: row.segmentoempresa,
          // oferente_ligareunion: row.ligareunion,
          oferente_ofertas: row.ofertas,
          oferente_telefono: row.telefono,
          oferente_demandas: row.demandas,
          oferente_empresa: row.empresa,
          oferente_pais: row.pais,
          oferente_codpais: row.codpais,
          oferente_ciudad: row.ciudad,
          oferente_ramo: row.ramo,
          //  oferente_logo: row.logo,
          oferente_uid: row.uid,
          oferente_estado: "aceptada",
          hora_reunion: horaReunion,
          //    ligareunion: row.ligareunion, // creo que esta bien
          fecha_solicitud: Date.now().toString(),
        })
        .then(function () {
          firebase
            .firestore()
            .collection("rondadenegocios")
            .doc(row.uid)
            .update({
              [`horas.${horaReunion}`]: estadoReunion,
            })
        })
        .then(function () {
          firebase
            .firestore()
            .collection("rondadenegocios")
            .doc(docRonda.uid)
            .update({
              [`horas.${horaReunion}`]: estadoReunion,
            })
        })
        .then(function () {
          setOpen(false)
          alert(
            currentLocale === "en"
              ? modal_reunirme_ronda.texto_alerta_reunion_solicitada.en
              : currentLocale === "pt"
              ? modal_reunirme_ronda.texto_alerta_reunion_solicitada.pt
              : modal_reunirme_ronda.texto_alerta_reunion_solicitada.es
          )
        })
        console.log("modal_reunirme_ronda.link_planilla_reuniones" ,modal_reunirme_ronda.link_planilla_reuniones)

      axios.post(modal_reunirme_ronda.link_planilla_reuniones, {
        demandante_email: docRonda.email,
        demandante_nombrerep: docRonda.nombrerep,
        demandante_paisesopera: docRonda.paisesopera,
        demandante_nombre: docRonda.nombre,
        demandante_apellido: docRonda.apellido,
        demandante_ofertas: docRonda.ofertas,
        demandante_telefono: docRonda.telefono,
        demandante_demandas: docRonda.demandas,
        demandante_empresa: docRonda.empresa,
        demandante_pais: docRonda.pais,
        demandante_ciudad: docRonda.ciudad,
        demandante_ramo: docRonda.ramo,
        //demandante_logo: docRonda.logo,
     //   demandante_uid: docRonda.uid,
    //    demandante_estado: "aceptada",
        oferente_email: row.email,
        oferente_nombrerep: row.nombrerep,
        oferente_paisesopera: row.paisesopera,
        oferente_ofertas: row.ofertas,
        oferente_telefono: row.telefono,
        oferente_demandas: row.demandas,
        oferente_empresa: row.empresa,
        oferente_pais: row.pais,
        oferente_ciudad: row.ciudad,
        oferente_ramo: row.ramo,
        // oferente_uid: row.uid,
        // oferente_estado: "aceptada",
        hora_reunion: horaReunion,
        fecha_solicitud: Date.now().toString(),
      })

      setHoraReunion("")
    } else {
      alert(
        currentLocale === "en"
          ? alert_elegir_hora.en
          : currentLocale === "pt"
          ? alert_elegir_hora.pt
          : alert_elegir_hora.es
      )
    }
  }

  const currentLocale = intl.locale

  const body = (
    <div className={classes.paper}>
      <CssBaseline />
      <Hidden xsDown>
        <Typography
          component="h5"
          variant="h5"
          style={{
            fontSize: `${modal_reunirme_ronda.texto_titulo_reunirme.fuente_size}vw`,
          }}
        >
          {currentLocale === "en"
            ? modal_reunirme_ronda.texto_titulo_reunirme.en
            : currentLocale === "pt"
            ? modal_reunirme_ronda.texto_titulo_reunirme.pt
            : modal_reunirme_ronda.texto_titulo_reunirme.es}
        </Typography>
      </Hidden>

      <Hidden smUp>
        <Typography component="p" variant="p">
          {currentLocale === "en"
            ? modal_reunirme_ronda.texto_titulo_reunirme.en
            : currentLocale === "pt"
            ? modal_reunirme_ronda.texto_titulo_reunirme.pt
            : modal_reunirme_ronda.texto_titulo_reunirme.es}
        </Typography>
      </Hidden>
      <br />
      <hr />
      <div style={{ margin: 0, textAlign: "center" }}>
        <br />{" "}
        <Typography noWrap variant="h6" component="h6" style={{ fontSize: 24 }}>
          {row.empresa}
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          <span style={{ fontStyle: "italic", fontSize: 12 }}>
            {row.demandas}
          </span>
        </Typography>
      </div>
      <br />

      <Typography variant="body2" component="p">
        {/*  <span style={{ fontWeight: "bold" }}>
          Tipo de solución/producto/servicio:{" "}
        </span> */}
        <span style={{ fontStyle: "italic" }}>{row.ofertas}</span>
      </Typography>
      <br />
      {!reunionYaSolicitada ? (
        <>
          <InputLabel id="horaLabel" style={{ padding: 5, marginTop: 30 }}>
            {currentLocale === "en"
              ? texto_elegir_horas_disponibles.en
              : currentLocale === "pt"
              ? texto_elegir_horas_disponibles.pt
              : texto_elegir_horas_disponibles.es}
          </InputLabel>
          <Select
            style={{ width: "100%", padding: 5 }}
            id="horareunion"
            value={horaReunion}
            onChange={handleChangeHoraReunion}
          >
            {horasDisponibles.map(horaDisp => (
              <MenuItem value={horaDisp}>
                {new Date(parseInt(horaDisp)).toLocaleString()}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : (
        ""
      )}

      <form noValidate onSubmit={handleSubmit}>
        {reunionYaSolicitada ? (
          <>
            <Hidden xsDown>
              <div
                style={{
                  fontSize: `${modal_reunirme_ronda.texto_reunion_ya_solicitada.fuente_size}vw`,
                }}
              >
                {currentLocale === "en"
                  ? modal_reunirme_ronda.texto_reunion_ya_solicitada.en
                  : currentLocale === "pt"
                  ? modal_reunirme_ronda.texto_reunion_ya_solicitada.pt
                  : modal_reunirme_ronda.texto_reunion_ya_solicitada.es}
              </div>
            </Hidden>

            <Hidden smUp>
              <div>
                {currentLocale === "en"
                  ? modal_reunirme_ronda.texto_reunion_ya_solicitada.en
                  : currentLocale === "pt"
                  ? modal_reunirme_ronda.texto_reunion_ya_solicitada.pt
                  : modal_reunirme_ronda.texto_reunion_ya_solicitada.es}
              </div>
            </Hidden>
          </>
        ) : (
          <Button
            type="submit"
            fullWidth
            disabled={btnDisabled}
            variant="outlined"
            style={{
              marginTop: 20,
              background: `${modal_reunirme_ronda.boton_solicite_reunion.color_fondo}`,
              color: `${modal_reunirme_ronda.boton_solicite_reunion.color_letra}`,
            }}
          >
            {currentLocale === "en"
              ? modal_reunirme_ronda.boton_solicite_reunion.en
              : currentLocale === "pt"
              ? modal_reunirme_ronda.boton_solicite_reunion.pt
              : modal_reunirme_ronda.boton_solicite_reunion.es}
          </Button>
        )}
      </form>
    </div>
  )

  return (
    <div>
      <Button
        //  fullWidth
        variant="outlined"
        //          variant={boton_modal_aquiestoy.variante}
        style={{
          background: `${boton_confirmar_hora.color_fondo}`,
          color: `${boton_confirmar_hora.color_letra}`,
        }}
        //size={boton_modal_aquiestoy.size}
        onClick={handleOpen}
        size="small"
      >
        {currentLocale === "en"
          ? boton_confirmar_hora.en
          : currentLocale === "pt"
          ? boton_confirmar_hora.pt
          : boton_confirmar_hora.es}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ overflow: "scroll" }}
      >
        {body}
      </Modal>
    </div>
  )
}

export default injectIntl(ModalRondaElegirHorarioVisitante)
